import React from 'react';
import {Grid} from '@mui/material';

function Downloads() {

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <h4>Downloads</h4>
                <p>No downloads currently available</p>
            </Grid>
        </Grid>
    );
}

export default Downloads;