import { AppConfig } from "app/runtimeConstants";
import { splitApi } from "./api";
interface CourseUploadResult {
    uid: string;
    title: string;
    href: string;
    ver: string;
}
interface AdminCourseHistoryResponseItem {
    uid: string;
    email: string;
    surname: string;
    givenname: string;
    latestDateTaken: string;
    completed: string;
}
const courseApi = splitApi.injectEndpoints({
    endpoints: (build) => ({
        postCourseBinary: build.mutation<CourseUploadResult, { data: FormData, uid?: string; }>({
            query: (args) => ({
                url: `bin/course${args.uid ? `?courseUid=${args.uid}` : ""}`,
                method: "POST",
                body: args.data
            })
        }),
        getAdminCourseHistory: build.query<Array<{ student: string, latestDateTaken: string, completed: string; }>, string>({
            query: (courseUid) => `reports/course/${courseUid}`,
            transformResponse: (response: AdminCourseHistoryResponseItem[]) =>
                response.map(item => ({
                    student: `${item.surname}, ${item.givenname} (${item.email})`,
                    latestDateTaken: new Date(item.latestDateTaken).toLocaleString(),
                    completed: item.completed
                }))
        })
    })
});

export const { usePostCourseBinaryMutation, useGetAdminCourseHistoryQuery } = courseApi;

