import { AppBar, IconButton, Toolbar, Tooltip } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Column } from "react-data-grid";
import ColumnChooser from "./ColumnChooser";
import { DomainGridConfig } from "./getDomainGridConfig";
import GridSearch from "./GridSearch";

interface GridTopBarProps {
    allCols: DomainGridConfig["fieldLabels"],
    selectedCols: DomainGridConfig["fieldLabels"],
    onSelectedColsChange: (selectedCols: DomainGridConfig["fieldLabels"]) => void;

    onSearch: (value: string) => void;
    onForceRefetch: () => void;
}


export function RefreshButton(props: { onRefreshClick: () => void, tooltip?: string; }) {
    const title = props.tooltip ? props.tooltip : props.tooltip === "" ? "" : "Refresh";
    return (
        <Tooltip title={title}>
            <IconButton aria-label={title} onClick={props.onRefreshClick}>
                <RefreshIcon color="primary" />
            </IconButton>
        </Tooltip>
    );
}

export default function GridTopBar(props: GridTopBarProps) {

    return (
        <AppBar position="static" variant="elevation" elevation={0} sx={{ backgroundColor: "#DDE1E4" }}>
            <Toolbar sx={{ flexGrow: 1 }} variant="dense">
                <ColumnChooser allCols={props.allCols} selectedCols={props.selectedCols} onSelectedColsChange={props.onSelectedColsChange} />
                <RefreshButton onRefreshClick={props.onForceRefetch} />
                <span style={{ flexGrow: 1 }} />
                <GridSearch onSearch={props.onSearch} />
            </Toolbar>
        </AppBar>
    );
}