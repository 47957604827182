import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar, Tooltip } from "@mui/material";
import { Domain, DomainRecordBuilder, DomainSchema } from "selign-domain-model";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import ModalDMEditor from "../ModalDMEditor";

interface GridBottomBarProps {
    domain: Domain;
    onNewRecordCreated: () => void;
}

function SchemaMenu(props: { anchorEl: HTMLElement | null, onClose: () => void, onSchemaSelected: (selectedSchema: DomainSchema) => void, schemas: DomainSchema[]; }) {

    return (
        <Menu
            anchorEl={props.anchorEl}
            open={Boolean(props.anchorEl)}
            onClose={props.onClose}
            keepMounted
        >
            <MenuItem disabled>Add new record of type:</MenuItem>
            {
                props.schemas.filter(f => f.id[0] !== "onlineSelfPacedModule" && f.id[0] !== "scheduledEvent").map((schema) => (<MenuItem key={`${schema.id[0]}${schema.id[1]}${schema.id[2]}`} onClick={() => props.onSchemaSelected(schema)}>{schema.title}</MenuItem>))
            }
        </Menu>
    );
}

function AddRecordButton(props: { domain: Domain; onNewRecordCreated: () => void; }) {
    const schemaResult = DomainRecordBuilder.getSchemas(props.domain);
    const schemaError = !schemaResult.ok;
    const schemas = schemaError ? [] : schemaResult.val;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popupCourseEditorOpen, setPopupCourseEditorOpen] = React.useState(false);
    const [targetSchema, setTargetSchema] = React.useState<DomainSchema | undefined>(undefined);

    const handleAddButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (schemas.length === 1) {
            // 1 schema, open modal editor
            setTargetSchema(schemas[0]);
            setPopupCourseEditorOpen(true);
        } else if (schemas.length > 1) {
            // multiple schemas, show menu to pick
            setAnchorEl(event.currentTarget);
        } else {
            //schemaError should be true, should not reach here as the Add IconButton is disabled
        }

    };

    const handleMenuSchemaSelected = (selectedSchema: DomainSchema) => {
        setTargetSchema(selectedSchema);
        setAnchorEl(null);
        setPopupCourseEditorOpen(true);
    };

    let editor: JSX.Element = <></>;

    if (targetSchema !== undefined && popupCourseEditorOpen && anchorEl === null) {
        editor = <ModalDMEditor
            schemaId={targetSchema.id || schemas[0].id}
            domain={props.domain}
            action={"create"}
            open={popupCourseEditorOpen}
            onCancel={() => setPopupCourseEditorOpen(false)}
            onSuccess={() => { setPopupCourseEditorOpen(false); props.onNewRecordCreated(); }}
        />;
    }

    // const editor = targetSchema !== undefined ? <ModalDMEditor
    //     schema={targetSchema}
    //     action={"create"}
    //     open={popupCourseEditorOpen}
    //     onCancel={() => setPopupCourseEditorOpen(false)}
    //     onSuccess={() => { setPopupCourseEditorOpen(false); props.onNewRecordCreated(); }}
    // /> : null;

    return (
        <div>
            {editor}
            <Tooltip title={schemaError ? "An error was encountered, unable to add new record" : "Add Record"}>
                <IconButton color={schemaError ? "error" : "primary"} onClick={handleAddButtonClick} disabled={schemaError}>
                    <AddIcon />
                </IconButton>
            </Tooltip>

            <SchemaMenu
                schemas={schemas}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                onSchemaSelected={handleMenuSchemaSelected}
            />
        </div>
    );
}


export default function GridBottomBar(props: GridBottomBarProps) {
    // const schemaResult = DomainRecordBuilder.getSchemas(props.domain);
    // const schemaError = !schemaResult.ok;
    // const schemas = schemaError ? [] : schemaResult.val;
    // const [popupCourseEditorOpen, setPopupCourseEditorOpen] = React.useState(true);

    return (
        <div>
            <AppBar position="static" variant="elevation" elevation={0} sx={{ backgroundColor: "#DDE1E4" }}>
                <Toolbar sx={{ flexGrow: 1 }} variant="dense">
                    <AddRecordButton domain={props.domain} onNewRecordCreated={props.onNewRecordCreated} />
                </Toolbar>
            </AppBar>
            {/* {!schemaError && <ModalDMEditor
                schema={schemas[0]}
                action={"create"}
                open={popupCourseEditorOpen}
                onCancel={() => setPopupCourseEditorOpen(false)}
                onSuccess={() => { setPopupCourseEditorOpen(false); props.onNewRecordCreated(); }}
            />} */}
        </div>
    );

}