import React from "react";
import { useField, useFormikContext } from "formik";
import { FMuiCourseProps, FMuiProps, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";
import { FormControl, Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { AppConfig } from "app/runtimeConstants";
import { usePostCourseBinaryMutation } from "app/services/courseApi";
export default function FMuiCourseField(props: FMuiProps<FMuiCourseProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const [uploadSuccess, setUploadSuccess] = React.useState(false);
    const [uploadError, setUploadError] = React.useState("");
    const fc = useFormikContext();
    const [uploading, setUploading] = React.useState(false);

    const [postCourseBinary, { isLoading: isPosting }] = usePostCourseBinaryMutation();

    const buttonLabel = field.value && typeof (field.value) === "string" && field.value.length > 0 ? "Upload New Course Version" : "Upload Course";

    const fileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            if (file.size > AppConfig.limits.courseByte) {
                helpers.setError(`'${file.name}' is too large. Must be ${AppConfig.limits.courseByte.toLocaleString()} bytes or less.`);
                event.target.value = "";
            } else {
                const courseUid = props.courseUidKey in (fc.values as any) ? (fc.values as any)[props.courseUidKey] : undefined;
                let fd = new FormData();
                fd.append("file", file);
                setUploading(true);

                postCourseBinary({ data: fd, uid: courseUid }).unwrap().then(result => {
                    //helpers.setTouched(true);
                    helpers.setValue(result.href);

                    const existingCourseName = props.courseNameKey in (fc.values as any) ? (fc.values as any)[props.courseNameKey] : "";
                    if (existingCourseName === "") {
                        fc.setFieldValue(props.courseNameKey, result.title);
                    }
                    setUploadError("");
                    setUploadSuccess(true);
                    event.target.value = "";
                }).catch(error => {
                    //helpers.setTouched(true);
                    //helpers.setError(`${error && error.message ? error.message : "An error was encountered."}`);
                    setUploadError(`${error && error.message ? error.message : "An error was encountered."}`);
                    setUploadSuccess(false);
                    event.target.value = "";
                });
            }
        }
    };

    return (
        <FormControl>
            <LoadingButton
                variant="contained"
                component="label"
                color="primary"
                startIcon={<CloudUploadIcon />}
                loading={uploading}
            >
                {buttonLabel}
                <input
                    hidden
                    type="file"
                    accept={props.accept}
                    name="course-file-upload"
                    onChange={fileInputChange}
                />
            </LoadingButton>
            {inError && <Typography color="error">{errorMsg}</Typography>}
            {!uploadSuccess && uploadError && <Typography color="error">{uploadError}</Typography>}
            {getFMuiFieldHelperText(props.formHelperText)}
            {uploadSuccess && <Typography color="green">Upload Successful</Typography>}
        </FormControl>
    );
}