import { splitApi } from "./api";

export interface BasicUserInfo {
    uid: string;
    email: string;
    givenName: string;
    surname: string;
}

/** Contains endpoint definitions that are excusively for admin use. Note that endpoints defined elsewhere may also serve admin user requests (such as domainApi) */
const adminApi = splitApi.injectEndpoints({
    endpoints: (build) => ({
        getUserSearchResult: build.query<BasicUserInfo[], string>({
            query: (searchTerm) => `search/admin/user/${encodeURIComponent(searchTerm)}`
        })
    })
});

export const { useLazyGetUserSearchResultQuery } = adminApi;