import React from 'react';
import styled from "styled-components";
import { AppBar, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { OnlineEvent, InPersonEvent } from "./data/course";

interface CourseRegisterProps {
    onClose: () => void;
    open: boolean;
    course: OnlineEvent | InPersonEvent;
}
export default function CourseRegister(props: CourseRegisterProps) {

    return (
        <Dialog fullWidth maxWidth="lg" onClose={props.onClose} open={props.open}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                {getEventRegistrationTable(props.course)}
            </DialogContent>
            <DialogActions>
                <Button name="close" onClick={props.onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>

    );
}

function getEventRegistrationRows(course: OnlineEvent | InPersonEvent) {
    return (
        <>
            {course.scheduledEvents.map(fe =>
                <>
                    <TableRow key={fe.uid}>
                        <TableCell colSpan={4}>{fe.name}</TableCell>
                        <TableCell>{fe.availableSeats || 1}</TableCell>
                        <TableCell><Button variant="contained" color="primary">Register</Button></TableCell>

                    </TableRow>
                    {fe.schedule.map(s =>
                        <TableRow key={s.start.toString()}>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>{s.start.toLocaleString()}</TableCell>
                            <TableCell>{s.end.toLocaleString()}</TableCell>
                            <TableCell>{s.location}</TableCell>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>)}
                </>)}
        </>
    );
}

function getEventRegistrationTable(course: OnlineEvent | InPersonEvent) {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Session</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Available Seats</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getEventRegistrationRows(course)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}