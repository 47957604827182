import React from 'react';
import { Course, OnlineDocReview } from "./data/course";
import { UserCourseStatus } from "./data/userCourse";
import { Dialog, DialogTitle, AppBar, Toolbar, IconButton, Typography, Button, DialogActions, DialogContent, DialogContentText, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { Close as CloseIcon } from '@mui/icons-material';
import DocumentIcon from '@mui/icons-material/Description';
import LinkIcon from '@mui/icons-material/Link';
import { Link } from "react-router-dom";
import { getDocList } from "features/dmForms/formElements/FMuiDocField";
import { getMyScormSession } from "./myScormDataAPI";
import { formatDate } from "features/dmForms/formElements/internal";
interface ODR extends Course {
    details: OnlineDocReview;
}

export interface DocReviewPlayerProps {
    onClose: () => void;
    open: boolean;
    inReview: boolean; // note that it's not necessary to alter scorm behavior based on this
    course: ODR;
    // userCourseStatus: UserCourseStatus;
}


export default function DocReviewPlayer(props: DocReviewPlayerProps) {
    //@ts-ignore
    const api: any = window.API_1484_11;

    const [isChecked, setIsChecked] = React.useState(false);

    const [completionStatus, setCompletionStatus] = React.useState<"incomplete" | "completed" | "unknown" | "">("");
    const [downloaded, setDownloaded] = React.useState<string[]>([]);

    const [courseMode] = React.useState<"review" | "credit">(props.course.details.trackingMethod === "Attestation" ? "credit" : "review");
    const [isLoading, setIsLoading] = React.useState(true);

    const getDocLinks = (odr: ODR) => {
        return getDocList(odr.details.urls, undefined, docDownloaded, downloaded);
    };

    const allDownloaded = () => {
        let completed = true;
        props.course.details.urls.forEach(doc => {
            if (doc.uid && !downloaded.includes(doc.uid)) {
                completed = false;
            }
        });
        return completed;
    };

    const handleClose = (e: React.MouseEvent) => {
        if (courseMode === "credit") {
            saveCmi();
            api.Terminate();
        }
        props.onClose();
    };

    const handleAttestationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(event.target.checked);
        if(event.target.checked)
            setCompletionStatus("completed");
    };

    React.useEffect(() => {
        if (courseMode === "credit" && isLoading) {
            api.Initialize();
            const completionStatus = api.GetValue("cmi.completion_status");
            const rawSuspendData = api.GetValue("cmi.suspend_data");
            let parsedSuspendData: any = {};
            try {
                parsedSuspendData = JSON.parse(rawSuspendData);
            } catch (error) {

            }

            const suspendData = {
                downloaded: parsedSuspendData.downloaded || [],
                // attestationDate: parsedSuspendData.attestationDate || ""
            };

            setCompletionStatus(completionStatus === "completed" ? "completed" : "incomplete");
            setDownloaded(suspendData.downloaded || []);
            
            if(completionStatus === "completed") {
                setIsChecked(true);
            }

            // if (suspendData.attestationDate) {                
            //     setIsChecked(true);
            // }
            setIsLoading(false);
        }
        if (courseMode === "review" && isLoading) {
            setIsLoading(false);
        }
    }, [courseMode, isLoading, api]);

    const docDownloaded = (uid?: string) => {
        if (uid && !downloaded.includes(uid)) {
            const newDownloaded = [...downloaded, uid];
            setDownloaded(newDownloaded);
            saveCmi();
        }
    };

    const saveCmi = () => {
        if (courseMode === "credit") {
            api.SetValue("cmi.completion_status", completionStatus);
            api.SetValue("cmi.suspend_data", JSON.stringify({ downloaded: downloaded }));
        }
    };

    const getAttestation = () => {
        if (courseMode === "credit") {
            if (allDownloaded()) {
                return (
                    <DialogContentText>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    disabled={props.inReview}
                                    onChange={handleAttestationChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="I have reviewed all of the above"
                        />

                        {/* <TextField
                            disabled={props.inReview}
                            type="date"
                            variant="standard"
                            value={formatDate(new Date(attestationDate))}
                            onChange={(e) => setAttestationDate(e.currentTarget.value)}
                            inputProps={
                                { max: formatDate(new Date()) }
                            }
                            InputLabelProps={{ shrink: true }}
                        /> */}
                    </DialogContentText>
                );
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    return (
        <Dialog onClose={handleClose} open={props.open}>
            <DialogTitle>{props.course.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.course.description}
                </DialogContentText>
                {getDocLinks(props.course)}
                {getAttestation()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>


        </Dialog>
    );
}