import { Button } from "@mui/material";
import { AppConfig, getSchema, getSchemaFromLibrary } from "app/runtimeConstants";
import { usePostJsonDatumMutation } from "app/services/api";
import { usePostDomainRecordMutation } from "app/services/domainApi";
import ModalDMEditor from "features/dmAdmin/ModalDMEditor";
import React, { useCallback } from "react";
import { Datum, Datum_C, DomainRecordBuilder, DomainRecordValues, DomainSchema } from "selign-domain-model";

export default function DmeTestPageB() {
    const [postJsonDatum, pjdResult] = usePostJsonDatumMutation();
    //const domain = AppConfig.dl.getDomain({name: "userProfile"});
    const domain = AppConfig.dl.getDomain({ name: "courses" });
    const schemaResult = DomainRecordBuilder.getSchemas(domain);
    const schemaError = !schemaResult.ok;
    const schemas = schemaError ? [] : schemaResult.val;
    if(schemaError) throw new Error("Bad Schema");

    const odrSchema: DomainSchema = schemas[1] as DomainSchema;
    const [editorOpen, setEditorOpen] = React.useState(false);

    const [postDR, postDRresult] = usePostDomainRecordMutation();

    const handleDoAThing = () => {
        const odrSchema = getSchemaFromLibrary({ uid: "597ee65e-81ae-4067-a851-32f3c4c80f04", name: "onlineDocumentReview", version: 0 });
        const odr = {
            courseName: "Leap Test 29",
            description: "Test course. Take it.",
            color: "#3E326F",
            userGroups: [
                "ab69617c-be25-48b1-acbe-817ae68b50e6",
            ],
            crg: [
                "f294ca68-5b9d-41a0-a499-9a10053f4a95",
            ],
            active: "1",
            trackingMethod: "1",
            certificateYN: "2",
            certificateCourseName: undefined,
            expires: "2",
            expiryMo: undefined,
            docs: [
                {
                    uid: "4c689c2b-374d-42e7-996c-0bbbb188b41d",
                    name: "Document 1",
                    description: "The first, very important document.",
                    type: "text/plain",
                    url: "https://localhost:6001/bin/uid/4c689c2b-374d-42e7-996c-0bbbb188b41d",
                },
            ],
        } as DomainRecordValues<DomainSchema>;
        // const datumC: Datum_C = {
        //     data: odr,
        //     schemaName: "courses",
        //     userId: null
        // };
        const simpleC: Datum_C = {
            uid: null,            
            data: JSON.stringify(odr),
            schemaName: "courses",
            userId: null,
            userEmail: null,
            created: null,
            createdBy: null,
            createdByEmail: null
        };


        // const simpleResult = postJsonDatum(simpleC).unwrap().then(result => {
        //     debugger;
        // }).catch(reason => {
        //     debugger;
        // });
        //const odrPostResult = postJsonDatum(datumC);
        
        postDR({
            values: odr,
            action: "create",  
            //@ts-ignore          
            schema: odrSchema.val,
            serverSchemaName: domain.serverSchemaName
        })
    };

    return (
        <div>
            <Button variant="outlined" onClick={handleDoAThing}>Do a Thing</Button>
            <Button variant="contained" onClick={() => setEditorOpen(true)}>Open Modal</Button>
            <ModalDMEditor
                domain={domain}
                schemaId={odrSchema.id}
                action="create"
                open={editorOpen}
                onCancel={() => setEditorOpen(false)}
                onSuccess={() => setEditorOpen(false)}
            />
        </div>
    );
}