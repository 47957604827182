import { Tooltip, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import React from "react";
import ModalDMEditor from "../ModalDMEditor";
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material";
import { DomainSchema, DomainRecordAction, DomainRecord, Domain, DomainRecordValid, DomainRecordError } from "selign-domain-model";
import { getSchema } from "app/runtimeConstants";
interface IconModalDMEditorProps<T extends DomainSchema, A extends Exclude<DomainRecordAction, "read">> {
    domain: Domain;    
    action: A;
    initialValues?: DomainRecordValid<T, "read"> | DomainRecordError<T, "read">;
    onSuccess: () => void;
}

export function IconOpenModalDMEditor(props: IconModalDMEditorProps<DomainSchema, "create" | "update">) {
    const [open, setOpen] = React.useState(false);

    let content = <Dialog
        open={open}
        onClose={() => setOpen(false)}
    >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>This record cannot be edited</DialogContent>
        <DialogActions>
            <Button onClick={() => setOpen(false)}>Close</Button>
        </DialogActions>
    </Dialog>;

    if (open && props.initialValues) {
        const schema = getSchema(props.initialValues.schema, props.domain);
        if (schema.ok) {
            content = <ModalDMEditor
                domain={props.domain}
                schemaId={schema.val.id}
                action={props.action}
                initialValues={props.initialValues}
                open={open}
                onCancel={() => { setOpen(false); }}
                onSuccess={() => { setOpen(false); props.onSuccess(); }}
            />;
        }
    }

    return (
        <div>
            <Tooltip title="Edit" placement="right">
                <IconButton size="small" onClick={() => setOpen(true)}>
                    <OpenInNewIcon />
                </IconButton>
            </Tooltip>
            {content}
        </div>
    );
}