import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ReactJson from 'react-json-view';
import TextField from '@mui/material/TextField';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import { Fab } from "@mui/material";
interface JsonViewModalProps {
    onClose: () => void;
    open: boolean;
    title: string;
    json: string | any;
}

export default function JsonViewModal(props: JsonViewModalProps) {
    const strJson = typeof props.json !== "string" ? JSON.stringify(props.json) : props.json;

    const copyAllJson = () => {
        // let ct = document.getElementById("jvm-json-copy") as HTMLInputElement;
        // ct.select();
        navigator.clipboard.writeText(strJson);
    };
    return (
        <Dialog open={props.open} scroll="paper" maxWidth="md" fullWidth>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                <ReactJson
                    src={typeof props.json === "string" ? JSON.parse(props.json) : props.json}
                    iconStyle="triangle"
                    collapsed={2}
                    theme="rjv-default"
                />
                <Fab style={{ color: "gray", position: "absolute", top: "2rem", right: "1.5rem" }} onClick={copyAllJson}>
                    <FileCopyIcon />
                </Fab>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}