import React from 'react';
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { doSilentSignInAsync } from "./authSlice";


export default function PrivateRoute({ component: Component, render, ...rest }: RouteProps) {
    const dispatch = useAppDispatch();
    const authUser = useAppSelector(state => state.auth.user);

    //temp debug variables
    const authState = useAppSelector(state => state.auth);
    const oidcState = useAppSelector(state => state.oidc);
    //temp debug variables end

    const isOidcUserLoading = useAppSelector(state => state.oidc.isLoadingUser);
    const isOidcUserLoaded = useAppSelector(state => state.oidc.user !== null);

    let isAuthUserLoading = useAppSelector(state => state.auth.status.includes("pending"));
    const isAuthUserLoaded = useAppSelector(state => state.auth.user !== undefined);

    if (isOidcUserLoaded && !isAuthUserLoaded && !isAuthUserLoading) {
        dispatch(doSilentSignInAsync());
        isAuthUserLoading = true;
    }

    let routeIsReports = false;
    let routeIsAdmin = false;
    
    if (rest.path) {        
        if (Array.isArray(rest.path)) {
            rest.path.forEach(p => {
                if (p.startsWith("/admin") || p.startsWith("admin"))
                    routeIsAdmin = true;
                if (p.startsWith("/reports") || p.startsWith("reports"))
                    routeIsReports = true;
            });
        } else if (typeof rest.path === "string") {
            if (rest.path.startsWith("/admin") || rest.path.startsWith("admin"))
                routeIsAdmin = true;
            if (rest.path.startsWith("/reports") || rest.path.startsWith("reports"))
                routeIsReports = true;
        }
    }
    
    // doens't really matter if they open these routes, the API wont provide any data
    const isAuthorized = routeIsReports 
                            ? (authUser?.areas?.find(a => a.name === "Reports")!== undefined)
                            : routeIsAdmin
                                ? (authUser?.areas?.find(a => a.name === "Admin")!== undefined)
                                : true;
    
    return (
        <Route
            {...rest}
            render={
                (props) => {
                    if (!isAuthUserLoaded || !isAuthorized) {
                        return isOidcUserLoading || isAuthUserLoading ? <div /> : <Redirect to={{ pathname: '/signin', state: { from: props.location } }} />;
                    }
                    if (Component) {
                        return <Component {...props} />;
                    }
                    if (render) {
                        return render(props);
                    }
                }
            }
        />
        // <Route
        //   {...rest}
        //   render={({ location }) =>
        //     isAuthenticated ? (
        //       children
        //     ) : (
        //       <Redirect
        //         to={{
        //           pathname: "/signin",
        //           state: { from: location }
        //         }}
        //       />
        //     )
        //   }
        // />
    );
}
