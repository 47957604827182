import React from 'react';
import styled from 'styled-components';
import logoOng from '../../common/img/orarng_logo_lg.png';
import { useLocation } from 'react-router-dom';
import TopNavMenu, { NavItem } from './TopNavMenu/TopNavMenu';
import { useAppSelector } from "../../app/hooks";

const Container = styled.div`
    width: 100%;
    position: sticky;   
    /* top: 0;  */
    z-index: 1250; /* modal is 1300 */

    top: -16px;
    height: calc(148px + 16px);

    @media (max-width: 600px) {
        height: calc(108px + 16px);
    }

    &:before,
    &:after {
        content: "";
        display: block;
        height: 16px;
        position: sticky;
    }

    &:before {
        top: calc(148px - 16px);
        @media (max-width: 600px) {
            top: calc(108px - 16px);
        }
        box-shadow: 4px 4px 8px rgba(0,0,0,0.2); 
        width: calc(100% - 5px);

        /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    }

    &:after {
        background: linear-gradient(white 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 70%, transparent);
        top: 0;
        z-index: 1251;
    }
`;

const Content = styled.div`
    height: 148px;
    @media (max-width: 600px) {
        height: 108px;        
    }
    position: sticky;
    top: 0px;
    margin-top: -16px;
    z-index: 1252;
`;


const Top = styled.div`
    width: 100%;
    height: 100px;
    /* border-bottom: 1px solid black; */
    background-color: #FFF;
    display: flexbox;
    flex-direction: column;
    /* margin-bottom: 75px; */

    @media (max-width: 600px) {
        height: 60px;
        /* margin-bottom: 50px; */
    }
`;

const Bottom = styled.div`
    width: 100%;
    border-bottom: 1px solid #5E7180;
    border-top: 1px solid #5E7180;
    
    margin-bottom: 25px;
    background-color: #DDE1E4;    
    @media (max-width:600px) {
        /* background-color: unset; */
    }
`;
const NavMenuWrapper = styled.div`
    width: calc(100% - 165px);
    height: 3rem;
    margin-left: 165px;    
    background-color:#DDE1E4;
`;

const ImgLogo = styled.img`
    position: relative; 
    width: 150px;
    left: 10px;
    top: 10px;
    z-index: 1251;
    @media (max-width: 600px) {
        width: 100px;            
    }
`;

const HeaderText = styled.div`
    margin-left: 20px;
    margin-right: 10px;
    margin-top: auto;
    margin-bottom: auto;        
    font-weight: bold;
    font-size: 1.5rem;
    font-variant-caps: all-small-caps;        
    @media (max-width: 600px) {
        /* font-size: 1.2rem; */
        /* display: none; */
    }
    /* @media (max-width: 550px) {
        font-size: 1.2rem;
    } */
    @media (max-width: 480px) {
        font-size: 1.4rem;            
    }
    @media (max-width: 460px) {
        font-size: 1.3rem;            
    }
    @media (max-width: 435px) {
        font-size: 1.2rem;            
    }
    @media (max-width: 415px) {
        font-size: 1.1rem;            
    }
    @media (max-width: 390px) {
        font-size: 1rem;
    }
    @media (max-width: 370px) {
        font-weight: normal;
    }
    @media (max-width: 350px) {
        font-size: 0.9rem;
    }
    @media (max-width: 315px) {
        display: none;
    }
`;




export default function Header() {
    const location = useLocation();
    const authUser = useAppSelector(state => state.auth.user);
    
    //TODO: Fix this navitem mess
    const nav: NavItem[] =
        location.pathname.toLowerCase() === "/signin" || location.pathname.toLowerCase() === "/register" || location.pathname.toLowerCase() === "/resetpassword" || location.pathname.toLowerCase() === "/dmetest"
            ? []
            : [
                { label: "Home", to: "/" },
                { label: "User Profile", to: "/userprofile" },
                { label: "Courses", to: "/courses" },
                { label: "Downloads", to: "/downloads" }
            ];
    if(nav.length > 0) {        
        if (authUser?.areas?.find(a => a.name === "Reports")!== undefined)
            nav.push({ label: "Reports", to: "/reports" })
        if (authUser?.areas?.find(a => a.name === "Admin")!== undefined)
            nav.push({ label: "Admin", to: "/admin" })
        
        nav.push({ label: "Sign Out", to: "/signout", group: "right" });
    }

    return (
        <Container>
            <Content>
                <Top>
                    <ImgLogo src={logoOng} alt="Oregon National Guard" />
                    <HeaderText>
                        Oregon National Guard
                    <br />
                    Learning Management System
                </HeaderText>
                </Top>
                <Bottom>
                    <NavMenuWrapper>
                        <TopNavMenu NavItems={nav} />
                    </NavMenuWrapper>
                </Bottom>
            </Content>
        </Container>

    );
}