import { AppConfig, getDomainRecordValidator, userProfileDS } from "app/runtimeConstants";
import { DomainRecordError, DomainRecordValid, DomainRecordAction, AuthUser } from "selign-domain-model";


import userManager from "./oidcConfig";
// TODO: Move this interface elsewhere

// TODO: Make an API base class - clean this non-DRY mess up

type UserProfileDS = typeof userProfileDS;



function mapUserProfile<T extends DomainRecordAction>(datum: any, action: T) {
    const drv = getDomainRecordValidator(userProfileDS);
    return drv.validate(datum, action)
        .map(v => v)
        .mapErr(v => v);
}


// export interface AuthUser {
//     uid: string;
//     email: string;
//     profile?: DomainRecordValid<UserProfileDS, "read"> | DomainRecordError<UserProfileDS, "read">;
//     areas?: ElevatedArea[];
// }
// interface ElevatedArea {
//     name: "Admin" | "Reports";
//     modules: ElevatedModule[];
// }
// export interface ElevatedModule {
//     name: "Users" | "Courses" | "Profile" | "Home" | "UserGroups"; // UserGroups not implemented server side ATM
//     actions: Action[];
// }
// export interface UserGroup {
//     uid: string;
//     name: string;
//     description: string | null;
// }
// interface Action {
//     name: string;
//     actionKey: string;
//     targetUserGroup: null | UserGroup;
//     targetJsonDatumUids: string[];
// }
// export interface UserProfile {
//     givenName: string;
//     surname: string;
//     organization: any;
//     [key: string]: any;
// }
export interface PasswordChange {
    oldPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}

//credentials: AuthCredentials, userProfile:any
export async function updateUserProfile(userProfile: DomainRecordValid<UserProfileDS, "update">, token: string, newEmail: boolean) {
    const newEmailAddress = userProfile.value["email"];
    const newPassword = userProfile.value["newPassword"];
    const confirmNewPassword = userProfile.value["confirmNewPassword"];
    const currentPassword = userProfile.value["currentpassword"];

    if (newEmail) {
        // update email address
        let formData = new FormData();
        formData.append("NewEmail", newEmailAddress);

        const response = await fetch(`${AppConfig.url.auth}/identity/account/manage/email?handler=ChangeEmail`, {
            method: "post",
            headers: { 'Authorization': `Bearer ${token}` },
            credentials: "include",
            body: formData
        });

        if (!response.ok) {
            console.log(`Change Email ${response.status} Error ${JSON.stringify(response)}`);
            let responseBody = await response.text();
            return Promise.reject(new Error(responseBody));
        }
    }

    if (newPassword
        && currentPassword
        && confirmNewPassword === newPassword) {
        // only update the password if the user entered an old, new, and confirmNew password 

        let formData = new FormData();
        formData.append("OldPassword", currentPassword);
        formData.append("NewPassword", newPassword);
        formData.append("ConfirmPassword", confirmNewPassword);

        const response = await fetch(`${AppConfig.url.auth}/identity/account/manage/changepassword`, {
            method: "post",
            headers: { 'Authorization': `Bearer ${token}` },
            credentials: "include",
            body: formData
        });

        if (!response.ok) {
            console.log(`Change Password ${response.status} Error: ${JSON.stringify(response)}`);
            let responseBody = await response.text();
            return Promise.reject(new Error(responseBody));
        }
    }

    // send userProfile to api
    let formData = new FormData();
    formData.append("userProfile", JSON.stringify(userProfile.value)); //`'${JSON.stringify(userProfile)}'`
    const userProfileResponse = await fetch(`${AppConfig.url.api}/mydata/userProfile`, {
        method: "POST",
        headers: { 'Authorization': `Bearer ${token}` },
        credentials: "include",
        body: formData
    });

    if (userProfileResponse.ok) {
        const userProfileData = await userProfileResponse.json();
        const authUser: AuthUser = {
            uid: userProfileData.uid,
            email: userProfileData.email,
            profile: (await mapUserProfile({ ...(JSON.parse(userProfileData.profile)), ...{ uid: userProfileData.uid, email: userProfileData.email } }, "read").resolve()).val,
            areas: userProfileData.areas
        };
        return authUser;
    } else {
        console.log(`User Profile Update ${userProfileResponse.status} Error: ${JSON.stringify(userProfileResponse)}`);
        if (userProfileResponse.status === 400) {
            // bad request
            let responseBody = await userProfileResponse.text();
            return Promise.reject(new Error(responseBody));
        }
        return Promise.reject(new Error(`Unable to update user profile. Please try again.`));
    }
}
export async function doRegister(up: DomainRecordValid<UserProfileDS, "create">, performSignin: boolean = true) {
    // const values= {
    //     email: credentials.email,
    //     password: credentials.password,
    //     userProfile: userProfile
    // };

    try {
        let formData = new FormData();
        formData.append('Email', up.value["email"]);
        formData.append('Password', up.value["password"]);
        formData.append('ConfirmPassword', up.value["confirmPassword"]);
        if (!performSignin) {
            formData.append('NoSignin', "true");
        }

        const response = await fetch(`${AppConfig.url.auth}/identity/account/register`, {
            method: "post",
            credentials: "include",
            body: formData
        });
        if (response.ok) {
            const data = await response.json();
            const authUser: AuthUser = {
                uid: data.uid,
                email: data.email,
                profile: undefined, // data.profile,
                areas: undefined

            };
            // send userProfile to api
            const token = (performSignin ? await userManager.signinSilent() : await userManager.getUser())?.access_token || null;;
            formData = new FormData();
            delete up.value.uid;
            delete up.value.email;
            delete up.value.password;
            delete up.value.confirmPassword;
            formData.append("userProfile", JSON.stringify(up.value)); //`'${JSON.stringify(userProfile)}'`
            const userProfileResponse =
                performSignin
                    ? await fetch(`${AppConfig.url.api}/mydata/userProfile`, {
                        method: "POST",
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                        credentials: "include",
                        body: formData
                    })
                    : await fetch(`${AppConfig.url.api}/data/schemaName/userProfile`, {
                        method: "POST",
                        headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'content-type': "application/json" },
                        credentials: "include",
                        body: JSON.stringify({
                            userId: authUser.uid,
                            data: JSON.stringify(up.value)
                        })
                    });
            // TODO: create a generic JsonDatumDTO class that when schema is userprofile, the data has given, surname etc
            const userProfileData = await userProfileResponse.json();
            const updProfile = performSignin ? JSON.parse(userProfileData.profile) : JSON.parse(userProfileData.data);

            authUser.profile = (await mapUserProfile({ ...updProfile, ...{ uid: userProfileData.uid, email: performSignin ? userProfileData.email : authUser.email } }, "read").resolve()).val; // userProfileData.email
            return authUser;
        } else {
            console.log(`Register ${response.status} Error: ${JSON.stringify(response)}`);
            if (response.status === 400) {
                // bad request
                let responseBody = await response.text();
                return Promise.reject(new Error(responseBody));
            }
            return Promise.reject(new Error(`Unable to register. Please try again.`));
        }
    }
    catch (error) {
        // fetch threw an exception (general network error)
        console.log(`Register Network Error: ${error}`);
        return Promise.reject(new Error(`Unable to register; a network error was encountered. Please try again later.`));
    }
}

export async function doSignOut() {
    await userManager.signoutRedirect();
}

export async function doSilentSignin() {
    // should only be called when the page is refreshed and after the OIDC state finishes loading
    //TODO: May need to rate limit calls to this (in dev, crash api server, refresh a logged in browser)
    const user = await userManager.signinSilent(); // { useReplaceToNavigate: true, data: state }
    const token = user.access_token;
    // get user info from API
    const response = await fetch(`${AppConfig.url.api}/mydata/user`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        credentials: "include"
    });

    const data = await response.json();
    const authUser: AuthUser = {
        uid: data.uid,
        email: data.email,
        profile: (await mapUserProfile({ ...(JSON.parse(data.profile)), ...{ uid: data.uid, email: data.email } }, "read").resolve()).val,
        areas: data.areas
    };
    return authUser;
}

export async function doSignIn(email: string, password: string) {

    let formData = new FormData();
    formData.append('Email', email);
    formData.append('Password', password);

    // let returnUrl = encodeURI(`${window.location.origin}/signin-callback`); // where to go on success
    // const returnUrl = window.location.origin;

    try {

        const response = await fetch(`${AppConfig.url.auth}/identity/account/login`, {
            method: "post",
            credentials: "include",
            body: formData
        });
        // a successful login responds with a 302 redirect - need to process signin at that point 

        if (response.ok) {
            // perform silent login            
            const user = await userManager.signinSilent(); // { useReplaceToNavigate: true, data: state }
            const token = user.access_token;
            // get user info from API
            const response = await fetch(`${AppConfig.url.api}/mydata/user`, {
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
                credentials: "include"
            });

            const data = await response.json();
            const authUser: AuthUser = {
                uid: data.uid,
                email: data.email,
                profile: (await mapUserProfile({ ...(JSON.parse(data.profile)), ...{ uid: data.uid, email: data.email } }, "read").resolve()).val,
                areas: data.areas
            };
            return authUser;
            // console.log(JSON.stringify(data));
            // const data = await response.json(); // won't ever be JSON when a redirect happens (302, which is "Ok")
            // // TODO: compare the parse code to what quicktype generates
            // if (data && data.uid && data.email) {
            //     return data as AuthUser;
            // } else {
            //     console.log(`SignIn malformed AuthUser data: ${JSON.stringify(data)}`);                
            //     return Promise.reject(new Error(`An unexpected error occured. Please try again.`));
            // }
        } else {
            // a non-Ok response was recieved
            console.log(`SignIn ${response.status} Error: ${JSON.stringify(response)}`);
            if (response.status === 400) {
                // bad request
                return Promise.reject(new Error(await response.text()));
            }
            return Promise.reject(new Error(`Unable to sign in. Please try again.`));
        }
    } catch (error) {
        // fetch threw an exception (general network error)
        console.log(`SignIn Network Error: ${error}`);
        return Promise.reject(new Error(`Unable to sign in; a network error was encountered. Please try again later.`));
    }


    // return new Promise<AuthUser>(
    //     (resolve, reject) => {
    //         if (email === "test@selign.com" && password === "longpassword")
    //             resolve({
    //                 uuid: "123a3917-d267-4d65-82a3-baf19a49afba",
    //                 email: "test@selign.com",
    //                 profile: {}
    //             });

    //         reject("Invalid Email and/or Password");
    //     }
    // );
}