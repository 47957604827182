import { userProfileDS } from "app/runtimeConstants";
import { splitApi } from "app/services/api";
import { store } from "app/store";
import { DomainRecordValid } from "selign-domain-model";
import { CourseSummary } from "./coursesAPI";

type UserProfileDS = typeof userProfileDS;
export interface CourseRequirementGroup {
    uid: string;
    name: string;
    description: string;
    sortOrder: number;    
    repeatEveryXMo: number; // requirement must be repeated every X months. 0 to disable.
    satisfiedWhen: "one-complete" | "all-complete" | "n/a";
    courseUids: string[];
    conditions: { uid: string, value: string | number; }[]; // "or" logic; when any one condition is true, group is required - uid is for user profile element. an Empty array matches everyone
}

export interface UserCourseRequirementGroup extends CourseRequirementGroup {
    isSatisfied: boolean;
    satisfiedUntil: string;
}

export default async function getUserCourseRequirementGroups(userProfile: DomainRecordValid<UserProfileDS, "read">, courseSummaries: CourseSummary[], token: string): Promise<UserCourseRequirementGroup[]> {
    const result = store.dispatch(splitApi.endpoints.getJsonData.initiate({schemaName: "courseRequirementGroup", actions: ["ViewCourse"], includeGroups: false}));
    const crgs = (await result.unwrap()).map(crgDatum => Object.assign({}, crgDatum.data, {uid: crgDatum.uid})) as CourseRequirementGroup[];
    //const crgs: CourseRequirementGroup[] = await ApiFetchOLD(CoursesApiEndpoints.GetCourseRequirementGroups, undefined, token);    
    
    let ucrgs: UserCourseRequirementGroup[] = [];
    crgs.sort((a,b) => a.sortOrder - b.sortOrder);
    crgs.forEach(ucrg => {
        let ucrgAdded = false;
        if (ucrg.conditions.length === 0) {
            ucrgs.push({...ucrg, ...isCourseRequirementGroupSatisified(courseSummaries, ucrg)});
            ucrgAdded = true;
        }
        else {
            ucrg.conditions.forEach(condition => {
                if (!ucrgAdded) {
                    const fs = Object.entries(userProfileDS.fields).find(([fieldName, schema]) => schema.dataElementUid === condition.value);
                    const fieldName = (fs && fs[0]) || "";
                    if (userProfile.value[fieldName] === condition.value) {                        
                        ucrgs.push({...ucrg, ...isCourseRequirementGroupSatisified(courseSummaries, ucrg)});
                        ucrgAdded = true;
                    }
                }
            });
        }
    });
    return ucrgs;
}

function isCourseRequirementGroupSatisified(courseSummaries: CourseSummary[], courseRequirementGroup: CourseRequirementGroup): { isSatisfied: boolean, satisfiedUntil: string; } {
    let oneComplete = false;
    let allComplete = true;
    let latestPossibleSatisfiedUntil = "";

    if (courseRequirementGroup.satisfiedWhen === "n/a") {
        return { isSatisfied: true, satisfiedUntil: ""};
    }

    courseRequirementGroup.courseUids.forEach(courseUid => {
        const cs = courseSummaries.find(c => c.course.uid.toUpperCase() === courseUid.toUpperCase());
        if (cs && cs.course.uid.toUpperCase() !== "9998B7D8-A367-4703-9E26-E2D1D544B378") { 
            // skips EPOC Part 1
            //TODO : Add multi-part course definition to schema
            if (cs.userCourse.latestDateCompleted) {
                const latestDateCompleted = new Date(cs.userCourse.latestDateCompleted);
                if (courseRequirementGroup.repeatEveryXMo === 0) {
                    // no need to take expiry into account
                    oneComplete = true;
                } else {
                    // must consider expiry                    
                    const xMoAgo = addMonths(new Date(), -courseRequirementGroup.repeatEveryXMo);
                    if (latestDateCompleted >= xMoAgo) {
                        // course is not expired by crg logic (may be expired by course logic)
                        oneComplete = true;
                        if (!latestPossibleSatisfiedUntil) {
                            latestPossibleSatisfiedUntil = addMonths(latestDateCompleted, courseRequirementGroup.repeatEveryXMo).toLocaleDateString();
                        } else {
                            const dExistingSatisifed = new Date(latestPossibleSatisfiedUntil);
                            const dNewSatisfied = addMonths(latestDateCompleted, courseRequirementGroup.repeatEveryXMo);
                            if (dNewSatisfied >= dExistingSatisifed)
                                latestPossibleSatisfiedUntil = dNewSatisfied.toLocaleDateString();
                        }
                    } else {
                        // course is expired by crg logic
                        allComplete = false;
                    }
                }
            } else {
                // course does not have a latestDateCompleted
                allComplete = false;
            }

        }
    });
    if (courseRequirementGroup.satisfiedWhen === "all-complete") {
        return { isSatisfied: allComplete, satisfiedUntil: allComplete ? latestPossibleSatisfiedUntil : "" };
    }
    if (courseRequirementGroup.satisfiedWhen === "one-complete") {
        return { isSatisfied: oneComplete, satisfiedUntil: oneComplete ? latestPossibleSatisfiedUntil : "" };
    }    
    return { isSatisfied: false, satisfiedUntil: "" };
}

function addMonths(date: Date, months: Number) {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
        date.setDate(0);
    }
    return date;
}