import React from 'react';
import { Grid } from '@mui/material';
import { useAppSelector } from "app/hooks";
import { selectAuthUser } from "features/auth";

import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import { AuthUser } from "selign-domain-model";
import { useGetDomainRecordsQuery } from "app/services/domainApi";


export function getInterpolatedMarkdown(md: string, authUser: AuthUser) {
    const regex = /({\S*})/g;
    const matches = md.match(regex);
    let imd = md;
    matches?.forEach(match => {
        const fieldName = match.substring(1, match.length - 1); // remove braces
        if (authUser.profile && authUser.profile.value && fieldName in authUser.profile.value) {
            imd = imd.replaceAll(match, String(authUser.profile.value[fieldName]));
        }
    });
    return imd;
}


export default function HomePage() {

    const authUser = useAppSelector(selectAuthUser).user;
    if (!authUser) throw Error("No user logged in");

    const { data, error, isLoading } = useGetDomainRecordsQuery({ elevated: false, getDomainBy: { name: "home" } });

    const md: string =
        isLoading
            ? "Loading"
            : data !== undefined && data.length === 1 && data[0].data.isValid
                ? data[0].data.value["md"]
                : "Unable to load the Home Page";

    const imd = getInterpolatedMarkdown(md, authUser);

    return (
        <Grid container spacing={5} sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Grid item xs={12} sm={6} md={8}>
                <MDEditor.Markdown
                    source={imd}
                    rehypePlugins={[[rehypeSanitize]]}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
                <h4>Downloads</h4>                
                <p>No downloads available</p>
                <br/>                
                {/* <h4>Classroom Course Calendar</h4>                
                <p>No classes available for registration</p> */}
            </Grid>
        </Grid>
    );
}
