import { useField } from "formik";
import { FMuiEventSchedulerProps, FMuiProps, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";
import React from "react";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Box, ListItemButton } from "@mui/material";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AddIcon from '@mui/icons-material/Add';
import EventIcon from '@mui/icons-material/Event';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { DateTime } from "luxon";
import { Calendar, luxonLocalizer} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { DateTimeValidationError } from "@mui/x-date-pickers";
import { isObjectWithKey } from "selign-domain-model";

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

export interface EventScheduleItem {
    /** unique identifier for item in a collection */
    id: number;
    /** start date and time, ISO */
    start: string;
    /** end date and time, ISO */
    end: string;
    location: string;
    /** title is specific to the event, ex: Day 1 */
    title: string;
    /** optional description/notes */
    description: string;
}





function parseFieldValue(value: any): EventScheduleItem[] {
    //TODO actual parsing of field value to EventScheduleItem[]
    if (Array.isArray(value)) {
        return value as EventScheduleItem[];
    } else {
        return [value as EventScheduleItem];
    }
}

function getNewId(values: EventScheduleItem[]): number {
    let id = 0;
    for (let i = 0, found = false; !found; i++) {
        if (values.find(v => v.id === i) === undefined) {
            found = true;
            id = i;
        }
    }
    return id;
}

function getNextDefaultValue(values: EventScheduleItem[]): EventScheduleItem {
    if (values.length === 0) {
        // const now = new Date(Date.now());
        // const start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8, 0, 0, 0);
        // const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 17, 0, 0, 0);

        const now = DateTime.now();
        const start = now.set({ hour: 8, minute: 0, second: 0, millisecond: 0 });
        const end = now.set({ hour: 17, minute: 0, second: 0, millisecond: 0 });
        return {
            id: getNewId(values),
            start: start.toString(),
            end: end.toString(),
            location: "",
            title: "Day 1",
            description: ""
        };
    } else {
        const previous = values[values.length - 1];
        const start = DateTime.fromISO(previous.start).plus({ day: 1 });
        const end = DateTime.fromISO(previous.end).plus({ day: 1 });
        const re = /(Day )(\d*)/g;
        const matches = [...previous.title.matchAll(re)];
        const title = matches.length === 1 && matches[0].length === 3 ? `Day ${Number(matches[0][2]) + 1}` : previous.title;

        return {
            id: getNewId(values),
            start: start.toString(),
            end: end.toString(),
            location: previous.location,
            title: title,
            description: ""
        };
    }
}

function sortEventScheduleItems(a: EventScheduleItem, b: EventScheduleItem) {
    if (a.start < b.start) {
        return -1;
    }
    if (a.start > b.start) {
        return 1;
    }
    // a must be equal to b
    return 0;
}



export function FMuiEventScheduler(props: FMuiProps<FMuiEventSchedulerProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [fmuiInError, fmuiErrorMsg] = getFMuiFieldErrorState(field, meta); // for the entire FMuiEventScheduler
    const [errors, setErrors] = React.useState({
        start: "",
        end: "",
        location: "",
        title: "",
        desc: ""
    });

    const [value, setValueState] = React.useState<EventScheduleItem[]>(parseFieldValue(field.value));
    const [editorValue, setEditorValue] = React.useState<EventScheduleItem>(getNextDefaultValue(value));
    const [mode, setMode] = React.useState<"add" | "update">("add");
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const labelId = `label-${props.name}`;

    const changeEditorValue = (field: keyof EventScheduleItem, newValue: EventScheduleItem[typeof field]) => {
        setEditorValue({ ...editorValue, [field]: newValue });
    };
    const setValue = (newValue: EventScheduleItem[]) => {
        setValueState(newValue);
        helpers.setValue(newValue);
    };

    const handleAdd = () => {
        if (!hasError()) {
            const newValue = [...value, editorValue].sort(sortEventScheduleItems);
            setValue(newValue);
            setEditorValue(getNextDefaultValue(newValue));
        }
    };

    const handleDelete = (idx: number, item: EventScheduleItem) => {
        const removeIdx = value.findIndex((v) => v === item);
        if (idx === removeIdx) {
            const newValue = ([] as EventScheduleItem[]).concat(value);
            newValue.splice(removeIdx, 1);
            setValue(newValue.sort(sortEventScheduleItems));

        }
    };

    const handleUpdateMode = (item: EventScheduleItem) => {
        setMode("update");
        setEditorValue(item);
    };

    const handleUpdate = () => {
        if (!hasError()) {
            const idx = value.findIndex(v => v.id === editorValue.id);
            const newValue = [...value];
            newValue[idx] = editorValue;
            setValue(newValue);
            setEditorValue(getNextDefaultValue(newValue));
            setMode("add");
        }
    };

    const handleCancelUpdate = () => {
        setEditorValue(getNextDefaultValue(value));
        setMode("add");
    };

    const handleDateTimeError = (field: "start" | "end", error: DateTimeValidationError, value: DateTime | null) => {
        switch (error) {
            case "minDate":
                setErrors({
                    ...errors,
                    [field]: "Must be after Start"
                });
                break;
            case null:
                if (errors[field] !== "") {
                    setErrors({
                        ...errors,
                        [field]: ""
                    });
                }
                break;
            default:
                setErrors({
                    ...errors,
                    [field]: `Error (${error})`
                });
        }
    };

    const handleRequiredTextFieldBlur = (field: "location" | "title", e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        const value = e.target.value;
        if (value === "") {
            setErrors({
                ...errors,
                [field]: "Required"
            });
        } else {
            if (errors[field] !== "") {
                setErrors({
                    ...errors,
                    [field]: ""
                });
            }
        }
    };

    const isFieldError = (field: keyof typeof errors) => {
        return errors[field] !== "";
    };

    const hasError = () => {

        // check for errors in untouched fields - start/end should be fine
        let hasErrors = false;
        let newErrors: Partial<typeof errors> = {};
        if (editorValue.title === "" && errors.title === "") {
            // title is "untouched"
            newErrors.title = "Required";
            hasErrors = true;
        }
        if (editorValue.location === "" && errors.location === "") {
            // location is "untouched"
            newErrors.location = "Required";
            hasErrors = true;
        }

        if (hasErrors) {
            setErrors({ ...errors, ...newErrors });
            return true;
        } else {
            let hasError = false;
            Object.keys(errors).forEach(key => {
                if (errors[key as keyof typeof errors] !== "") hasError = true;
            });
            return hasError;
        }
    };

    const addButton = <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAdd}>Add</Button>;
    const updateCancelButtons = <>
        <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={handleUpdate}>Update</Button>
        <Button variant="text" sx={{ marginLeft: "1rem" }} color="primary" onClick={handleCancelUpdate}>Cancel</Button>
    </>;

    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            {/* <Box>
            <Calendar 
                    localizer={localizer}
                    events={value}
                    startAccessor={(event) => DateTime.fromISO(event.start).toJSDate()}
                    endAccessor={(event) => DateTime.fromISO(event.end).toJSDate()}
                    titleAccessor={(event) => `${event.title}: ${event.location}`}
                    allDayAccessor={(event) => false}
                    
                    style={{height: 300}}                    
                />
            </Box> */}


            {/* <InputLabel id={labelId}>{props.label}</InputLabel> */}
            <Typography>All dates and times below are in your local timezone ({currentTimeZone})</Typography>

            <FormControl fullWidth>
                <DateTimePicker
                    label="Start"
                    value={DateTime.fromISO(editorValue.start)}
                    onChange={(e) => changeEditorValue("start", e?.toString() ?? "")}
                    onError={(error, value) => handleDateTimeError("start", error, value)}
                />
                {isFieldError("start") && <FormHelperText error>{errors.start}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth>
                <DateTimePicker
                    label="End"
                    value={DateTime.fromISO(editorValue.end)}
                    onChange={(e) => changeEditorValue("end", e?.toString() ?? "")}
                    minDateTime={DateTime.fromISO(editorValue.start)}
                    onError={(error, value) => handleDateTimeError("end", error, value)}
                />
                {isFieldError("end") && <FormHelperText error>{errors.end}</FormHelperText>}
            </FormControl>

            <TextField
                fullWidth
                label="Location"
                value={editorValue.location}
                onChange={(e) => changeEditorValue("location", e.target.value)}
                error={isFieldError("location")}
                helperText={errors.location}
                onBlur={(e) => { handleRequiredTextFieldBlur("location", e); }}
            />

            <TextField
                fullWidth
                label="Title"
                value={editorValue.title}
                onChange={(e) => changeEditorValue("title", e.target.value)}
                error={isFieldError("title")}
                helperText={errors.title}
                onBlur={(e) => { handleRequiredTextFieldBlur("title", e); }}
            />

            <TextField
                fullWidth
                label="Description/Notes"
                value={editorValue.description}
                onChange={(e) => changeEditorValue("description", e.target.value)}
                error={isFieldError("desc")}
                helperText={errors.desc}
            />

            {mode === "add" ? addButton : updateCancelButtons}

            {getFMuiFieldHelperText(props.formHelperText)}
            <List sx={{marginTop: "1rem"}}>
                {
                    value.map((item, idx) =>
                        <ListItem
                            key={idx}
                            disablePadding
                            secondaryAction={
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => handleDelete(idx, item)}
                                    size="large"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        >
                            <ListItemButton onClick={() => handleUpdateMode(item)} selected={mode === "update" && editorValue.id === item.id}>
                                <ListItemAvatar>
                                    <Avatar>
                                        <EventIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.title}
                                    secondary={`${DateTime.fromISO(item.start).toLocaleString(DateTime.DATETIME_SHORT)} - ${DateTime.fromISO(item.end).toLocaleString(DateTime.DATETIME_SHORT)}`}
                                />
                            </ListItemButton>
                        </ListItem>
                    )
                }
                {
                    value.length === 0 && <ListItem key={-1}>
                        <ListItemAvatar>
                            <Avatar>
                                <EventIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Nothing scheduled" />
                    </ListItem>
                }
            </List>
            {fmuiInError && <FormHelperText error>{fmuiErrorMsg}</FormHelperText>}
        </FormControl>
    );
}