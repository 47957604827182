import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { DMForm, DMFormProps } from "features/dmForms";
import { Datum_D, Domain, DomainRecord, DomainRecordAction, DomainRecordError, DomainRecordValid, DomainRecordValues, DomainSchema, IDx_UID } from "selign-domain-model";
import { FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { getDomainRecordValidator, getSchema, getSchemaFromLibrary } from "app/runtimeConstants";
import { domainApi, useGetDomainRecordDetailsQuery, usePostDomainRecordMutation } from "app/services/domainApi";
import { splitApi } from "app/services/api";
import React from "react";


interface ModalDMEditorProps<T extends DomainSchema, A extends Exclude<DomainRecordAction, "read">> {
    domain: Domain;
    schemaId: DomainSchema["id"];
    action: A;    
    initialValues?: DomainRecordValid<T, "read"> | DomainRecordError<T, "read">;
    //initialRecord: Datum_D;
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
}

export default function ModalDMEditor(props: ModalDMEditorProps<DomainSchema, "create" | "update">) {
    const [postDM, postDMresult] = usePostDomainRecordMutation();
    const user = useAppSelector(state => state.oidc.user);
    const authUserUid = useAppSelector(state => state.auth.user?.uid);
    

    const { enqueueSnackbar } = useSnackbar();
    if (!user || !authUserUid) {
        throw Error("No user logged in");
    }
    const getSchemaResult = getSchema(props.schemaId[IDx_UID], props.domain);
    if(!getSchemaResult.ok) {
        throw Error(`ModalDMEditor: schema '${props.schemaId[IDx_UID]}' does not exist on domain ${props.domain.id[IDx_UID]}`);
        
    }
    const schema = getSchemaResult.val;

    
    const theme = useTheme();
    const fullscreenDialogs = useMediaQuery(theme.breakpoints.down('md'));
    const dialogTitle = "";

    // const { data, error, isLoading, isFetching, refetch } = useGetDomainRecordDetailsQuery({values: props.initialValues || {}, getDomainBy: {uid: props.schemaId[IDx_UID]}, elevated: true})
    
    const [iv, setIv] = React.useState(props.initialValues);
    const [isDetailsLoading, setIsDetailsLoading] = React.useState(props.action === "update" && props.initialValues ? true : false);
    
    const dispatch = useAppDispatch();
    
    React.useEffect(() => {
        if(props.open && props.action === "update" && props.initialValues) {
            const x = dispatch(domainApi.endpoints.getDomainRecordDetails.initiate({
                values: props.initialValues,
                getDomainBy: {uid: props.domain.id[IDx_UID]},
                schemaId: props.schemaId,
                elevated: true
            }, { forceRefetch: true }));

            const fetchDetails = async () => {                
                const { data, error, isLoading } = await x;
                if(!isLoading) { //&& !error
                    if(data) {
                        setIv(data);
                    }
                    if(error) {
                        enqueueSnackbar(`An error was encountered`, {variant: "error"});
                        console.error(error);
                    }
                    
                    setIsDetailsLoading(false);
                }
            };
            
            fetchDetails(); // catch???
        }
    }, [props.open, props.action, props.initialValues, iv, isDetailsLoading, setIv, setIsDetailsLoading]);

    let content: JSX.Element = <div>Loading</div>;
    if (props.open) {
        if(!isDetailsLoading) {
            const drv = getDomainRecordValidator(schema);
            const { initialValues, validationSchema } =
                props.action === "create"
                    ? drv.getPropsForFormik("create")
                    : drv.getPropsForFormik("update", iv?.value);
    
    
            const dmFormProps: DMFormProps<typeof schema, typeof props.action> = {
                domainSchema: schema,
                action: props.action,
                areInitialValuesValid: props.initialValues?.isValid || false, // TODO: seems like this should be calculated here
                onUserCancel: function (): void {
                    props.onCancel();
                },
                forFormik: {
                    initialValues: initialValues,
                    validationSchema: validationSchema,
                    onSubmit: function <T extends DomainSchema>(values: DomainRecordValues<T>, formikHelpers: FormikHelpers<DomainRecordValues<T>>) {
                        postDM({
                            values: values,
                            action: props.action,
                            schemaId: schema.id,
                            domain: props.domain,
                            elevated: true
                        })
                        props.onSuccess();
                    }
                }
            };
            content = <DMForm {...dmFormProps} />;
        }
        

        // maxWidth = {props.kind === "homemd" ? "xl" : undefined}
        return (
            <Dialog open={props.open} fullScreen={fullscreenDialogs} maxWidth={undefined}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    {/* className={`${muiClasses.root}`} */}
                    <div style={{ minWidth: "500px" }}>
                        {content}
                    </div>
                </DialogContent>
            </Dialog>
        );
    } else {
        return null;
    }

}