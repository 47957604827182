import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { AppConfig } from "app/runtimeConstants";
import { Datum, Datum_C, Datum_P, Datum_R, Datum_U } from "selign-domain-model";
import { RootState } from '../store';

interface BinaryDatum {    
    uid: string;
    mimeType: string;
    name: string;
    description: string;
    url: string;
}
const baseQuery = fetchBaseQuery({
    baseUrl: `${AppConfig.url.api}/`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const token = (getState() as RootState).oidc.user?.access_token;
        if (token) {
            headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
    }
});

export const splitApi = createApi({
    baseQuery: retry(baseQuery, { maxRetries: 1 }),
    endpoints: (build) => ({
        getJsonDatum: build.query<Datum_P, string>({
            query: (uid) => `data/uid/${uid}`,
            transformResponse: (response: Datum_R) => parseResponse(response)            
        }),
        getJsonData: build.query<Array<Datum_P>, { schemaName: string, actions: Array<string>, includeGroups: boolean; }>({
            query: (args) =>
                `data/schemaname/${args.schemaName}/current${args.actions.length > 0
                    ? `?a=${args.actions.join("&a=")}${args.includeGroups
                        ? '&g=true'
                        : ''}`
                    : ""}`,
            transformResponse: (response: Datum_R[]) => response.map(item => parseResponse(item))
        }),
        postJsonDatum: build.mutation<Datum_P, Datum_C | Datum_U>({
            query: (datumCU) => ({
                url: `data/schemaName/${datumCU.schemaName}`,
                method: "POST",                
                body: datumCU
            }),
            transformResponse: (response: Datum_R) => parseResponse(response)
        }),
        getBinaryDatum: build.query<BinaryDatum, string>({
            query: (uid) => `bin/meta/uid/${uid}`,
            transformResponse: (response: Omit<BinaryDatum, "url">) => ({...response, url: `${AppConfig.url.api}/bin/uid/${response.uid}`})
        }),
        postBinaryDatum: build.mutation<BinaryDatum, FormData>({
            query: (data) => ({
                url: `bin`,
                method: "POST",
                body: data
            }),
            transformResponse: (response: Omit<BinaryDatum, "url">) => ({...response, url: `${AppConfig.url.api}/bin/uid/${response.uid}`})
        })
    }),
});

function parseResponse(response: Datum_R): Datum_P {
    //TODO: ensure response really matches JsonDatum
    let data = JSON.parse(response.data);
    const uid = response.uid.toLowerCase();
    data.uid = uid;
    // if ("uid" in data && typeof data.uid === "string") data.uid = data.uid.toLowerCase();

    return {
        ...response,
        uid: uid,
        data
    };
}

export const { useGetJsonDatumQuery, useGetJsonDataQuery, useGetBinaryDatumQuery, usePostBinaryDatumMutation, usePostJsonDatumMutation } = splitApi;