import styled, { css, keyframes } from 'styled-components';
import { IconButton } from '@mui/material';
export const slideIn = keyframes`
from {            
    right: -50%;
    opacity: 0.001;
}
to {            
    right: 0;
    opacity: 1;
}
`;

export const fadeIn = keyframes`
from {
    opacity: 0;     
}
to {
    opacity: 1; 
}
`;

export const NavMenuHamburgerMixin = css`
position: absolute;
left: 0; 
z-index: inherit;
`;

export const NavMenuContainer = styled.div<{ isHamburger: boolean } & React.HTMLProps<HTMLDivElement>>`
${props => props.isHamburger && NavMenuHamburgerMixin}
height: ${props => props.isHamburger ? "unset" : "100%"};
width: 100%;
display: flex;
flex-wrap: nowrap;  
overflow: hidden;
background-color: inherit;
`;
export const NavMenuFlexColumn = styled.div`
display: flex;
justify-content: space-around;    
align-items: center;   
position: relative;     
animation: ${slideIn} 800ms; 
`;
export const NavMenuColumn = styled.div`
display: block;
width: 100%;
`;
export const NavMenuHamburgerLinkContainer = styled.div`
animation: ${fadeIn} 750ms ease-in; 
`;

export const hamburgerLinkWrapperMixin = css`
padding-top: 1rem;
padding-bottom: 1rem;
padding-left: 1rem;
margin-left: 1rem;
border-bottom: 1px solid #5E7180;
`;
export const wideLinkWrapperMixin = css`
padding-left: 1rem;
padding-right: 1rem;
height: 100%;
display: flex;   
align-items: center;       
`;

export const NavMenuLinkWrapper = styled.div<{ isHamburger: boolean; isActive: boolean } & React.HTMLProps<HTMLDivElement>>`
white-space: nowrap;   
cursor: pointer;     
${props => props.isHamburger ? hamburgerLinkWrapperMixin : wideLinkWrapperMixin};
${props => props.isActive ? props.isHamburger ? hamburgerLinkMixin : wideLinkMixin : undefined };
`;

export const hamburgerLinkMixin = css`
/* border-left: 2px black solid; */
background-color: #173049;
a {
    color: white;
}
`;

export const wideLinkMixin = css`    
/* border-bottom: 2px black solid; */
background-color: #173049;
a {
    color: white;
}
`;

export const NavMenuHamburgerButtonWrapper = styled.div`
width: 100%;
`;
export const NavMenuHamburgerButton = styled(IconButton)`
&[style] {
    margin-left: auto !important;
    display: block !important;
}
`;