import React from "react";
import { FMuiDependentProps } from "./internal";
import { useFormikContext } from "formik";

export default function FMuiDependentFieldWrapper(props: FMuiDependentProps) {
    const [showField, setShowField] = React.useState(false);
    const [disableField, setDisableField] = React.useState(false);
    const { setFieldValue, setFieldTouched, values, touched } = useFormikContext();



    // all the variables needed for the effects (object (aka props) dependencies in effects are different everytime the function is called so the effect runs unnecessarily)
    // could probably clone the objects to state instead of all these variables though

    const fieldName = props.fieldName;
    const fieldValue = (values as any)[fieldName];


    const onlyShowWhen = props.onlyShowWhen !== undefined;
    const onlyShowWhenFieldValue = props.onlyShowWhen ? (values as any)[props.onlyShowWhen.fieldName] : undefined;
    const onlyShowWhenFieldName = props.onlyShowWhen ? props.onlyShowWhen.fieldName : undefined;
    const onlyShowWhenHasValue = props.onlyShowWhen ? props.onlyShowWhen.hasValue : undefined;

    const overwriteValueWhen = props.overwriteValueWhen !== undefined;
    const overwriteWhenFieldValue = props.overwriteValueWhen ? (values as any)[props.overwriteValueWhen.when.fieldName] : undefined;
    const overwriteWhenHasValue = props.overwriteValueWhen ? props.overwriteValueWhen.when.hasValue : undefined;
    const overwriteWhenNewValue = props.overwriteValueWhen ? props.overwriteValueWhen.newValue : undefined;

    React.useEffect(() => {
        if (onlyShowWhen) {
            // check if onlyShowWhen condition is satisfied
            let shouldShow = true;
            try {
                // eslint-disable-next-line eqeqeq
                shouldShow = (onlyShowWhenHasValue == onlyShowWhenFieldValue);
                setShowField(shouldShow);
            } catch (error) {
                throw new Error(`Dependent field wrapper for field '${fieldName}' is unable to determine value of dependent field'${onlyShowWhenFieldName}'.`);
            }

            try {
                if (!shouldShow) {
                    if (Object.getOwnPropertyNames(touched).includes(fieldName)) {
                        if (touched[fieldName as keyof typeof touched]) {
                            setFieldTouched(fieldName, false);
                        }
                    }
                    if (fieldValue) {
                        if (Array.isArray(fieldValue) && fieldValue.length > 0) {
                            setFieldTouched(fieldName, false);
                            setFieldValue(fieldName, []);
                        } else {
                            if (fieldValue !== "") {
                                setFieldValue(fieldName, "");
                            }
                        }
                    }
                }
            } catch (error) {
                throw new Error(`Dependent field wrapper for field '${fieldName}' is unable to reset the value after hiding the field. Error: ${error}`);
            }
        } else {
            if (!showField)
                setShowField(true);
        }
    }, [onlyShowWhen, onlyShowWhenHasValue, onlyShowWhenFieldValue, fieldName, onlyShowWhenFieldName, showField, fieldValue, setFieldTouched, setFieldValue, touched]);

    React.useEffect(() => {
        if (overwriteValueWhen) {
            // check to see if value is overwritten
            try {
                // eslint-disable-next-line eqeqeq
                if (overwriteWhenHasValue == overwriteWhenFieldValue) {
                    setDisableField(true);

                    // eslint-disable-next-line eqeqeq
                    if (fieldValue != overwriteWhenNewValue) {
                        setFieldValue(fieldName, overwriteWhenNewValue);
                    }
                } else {
                    if (disableField) setDisableField(false);
                }
            } catch (error) {
                throw new Error(`Dependent field wrapper for field '${fieldName}' encountered an error when overwriting its value because value of overwriteWhen rule. Error: ${error}`);
            }
        }
    }, [overwriteValueWhen, overwriteWhenHasValue, overwriteWhenFieldValue, fieldValue, overwriteWhenNewValue, fieldName, setFieldValue, disableField]);


    const child = React.cloneElement(props.dependentChild, { disabled: disableField });

    // note that the child must still be included if child hidden fields with children are to have their values reset too
    // SO DONT DO THIS: return showField ? child : null;
    return (
        <div style={showField ? {} : { display: "none" }}>

            {child}
        </div>
    );
}