import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Link, ClickAwayListener } from '@mui/material';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import { useWillContentsWrap } from './useWillContentsWrap';
import { NavMenuLinkWrapper, NavMenuContainer, NavMenuColumn, NavMenuHamburgerButtonWrapper, NavMenuHamburgerButton, NavMenuFlexColumn, NavMenuHamburgerLinkContainer } from './styledTopNavMenuComponents';


export interface NavItem {
    label: string;
    to: string;
    group?: "left" | "right";
}

interface NavMenuProps {
    NavItems: NavItem[];
}

export default function TopNavMenu(props: NavMenuProps) {
    const rootRef = React.useRef<HTMLDivElement>(null);
    const location = useLocation();
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = React.useState(false);
    const haveNavMenuItems = props.NavItems.length > 0;
    const contentsWillWrap = useWillContentsWrap(rootRef, 155, false);
    
    function handleHamburgerMenuClickAway() {
        if (isHamburgerMenuOpen)
            setIsHamburgerMenuOpen(false);
    }

    function handleHamburgerMenuClick() {
        setIsHamburgerMenuOpen(!isHamburgerMenuOpen);
    }

    function handleNavMenuLinkWrapperClick(e: React.MouseEvent) {
        let elem = e.target as Element;
        if (elem.tagName === "DIV") {
            elem.getElementsByTagName("a")[0].click();
        }
        if (elem.tagName === "A") {
            (elem as HTMLAnchorElement).click();
        }
        setIsHamburgerMenuOpen(false);
    }

    function getNavMenuItems(group: "all" | "left" | "right") {
        if (contentsWillWrap && !isHamburgerMenuOpen)
            return null;

        return props.NavItems
            .filter(i => group === "all" ? true : group === "right" ? i.group === "right" : i.group !== "right")
            .map((ni, idx) =>
                <NavMenuLinkWrapper key={idx} onClick={handleNavMenuLinkWrapperClick} isHamburger={contentsWillWrap} isActive={location.pathname === ni.to}>
                    <Link component={RouterLink} to={ni.to}>{ni.label}</Link>
                </NavMenuLinkWrapper>);
    }

    return (
        <NavMenuContainer isHamburger={contentsWillWrap} ref={rootRef}>
            
            {haveNavMenuItems && contentsWillWrap &&
                <ClickAwayListener onClickAway={handleHamburgerMenuClickAway}>
                    <NavMenuColumn >
                        <NavMenuHamburgerButtonWrapper>
                            <NavMenuHamburgerButton style={{ marginLeft: "auto", display: "block" }} onClick={handleHamburgerMenuClick}>{isHamburgerMenuOpen ? <MenuOpenIcon /> : <MenuIcon />}</NavMenuHamburgerButton>
                        </NavMenuHamburgerButtonWrapper>
                        {isHamburgerMenuOpen && <NavMenuHamburgerLinkContainer>{getNavMenuItems("all")}</NavMenuHamburgerLinkContainer>}
                        {!isHamburgerMenuOpen && getNavMenuItems("all")}
                    </NavMenuColumn>
                </ClickAwayListener>}

            {haveNavMenuItems && !contentsWillWrap && <NavMenuFlexColumn>{getNavMenuItems("left")}</NavMenuFlexColumn>}
            {haveNavMenuItems && !contentsWillWrap && <NavMenuFlexColumn style={{ marginLeft: "auto" }}>{getNavMenuItems("right")}</NavMenuFlexColumn>}
        </NavMenuContainer>
    );

}