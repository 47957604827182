import React from 'react';
import Grid from '@mui/material/Grid';
import SignInForm from "./SignInForm";

function Signin() {

// className={classes.root}
// className={classes.textButton}
    return (
        <Grid container spacing={5} >
            <Grid item xs={12} sm={6} md={4}>
                <SignInForm />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                {/* <h3>Welcome!</h3> */}
                <h4>NOTICE AND CONSENT</h4>
                <p>You are accessing a U.S. Government (USG) Information System (IS) that is provided for USG-authorized use only.</p>
                <p>By using this IS, you consent to the following conditions:</p>
                <ul style={{ textAlign: "justify", paddingLeft: "20px" }}>
                    <li>The USG routinely monitors communications occurring on this IS, and any device attached to this IS, for purposes including, but not limited to, penetration testing, COMSEC monitoring, network defense, quality control, and employee misconduct, law enforcement, and counterintelligence investigations.</li>
                    <li>At any time, the USG may inspect and/or seize data stored on this IS and any device attached to this IS. Communications occurring on or data stored on this IS, or any device attached to this IS, are not private. They are subject to routine monitoring and search.</li>
                    <li>Any communications occurring on or data stored on this IS, or any device attached to this IS, may be disclosed or used for any USG-authorized purpose.</li>
                    <li>Security protections may be utilized on this IS to protect certain interests that are important to the USG. For example, passwords, access cards, encryption or biometric access controls provide security for the benefit of the USG. These protections are not provided for your benefit or privacy and may be modified or eliminated at the USG's discretion.</li>
                </ul>
            </Grid>

        </Grid>
    );
}

export default Signin;