import { SvgIconProps } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Search from '@mui/icons-material/Search';
import FilterList from '@mui/icons-material/Sort';
// import PrereqIcon from '@mui/icons-material/ReportProblemOutlined';
import ReportProblemOutlined from '@mui/icons-material/ReportProblemOutlined';
import { FileCertificateIcon } from "../../common/FAIcons";
import styled from "styled-components";
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import AssignmentLate from '@mui/icons-material/AssignmentLate';

// style={{ verticalAlign: "middle", color: icm.courseExpiration.hasExpired ? "red" : "orange", fontSize: "1.5rem" }}

// TODO: Connect colors to theme and standardize API

export {FileCertificateIcon, InfoOutlined as MoreInfoIcon, Search as SearchIcon, FilterList as FilterListIcon};
export const CompulsionInfoIcon = styled(InfoOutlined)`
    font-size: 1rem;
    vertical-align: middle;
    margin-left: .25rem;    
    color: #2196f3;
`;

export const ExpiredCourseIcon = styled(ReportProblemOutlined)`
    font-size: 1.5rem;
    vertical-align: middle;
    color: #f44336;
`;

export const ExpiringCourseIcon = styled(ReportProblemOutlined)`
    font-size: 1.5rem;
    vertical-align: middle;
    color: #ff9800;
`;

// #94BDE1
export const PrereqInfoIcon = styled(InfoOutlined)`
    font-size: 1.5rem;
    vertical-align: middle;
    color: #2196f3;
`;

export const PrereqMissingIcon = styled(AssignmentLate)`
    vertical-align: middle;
    margin-right: 0.5rem;
    color: #f44336;
`;

export const PrereqSatisfiedIcon = styled(AssignmentTurnedIn)`
vertical-align: middle;
    margin-right: 0.5rem;
    color: #4caf50;
`;