import React, { useEffect } from 'react';
import { Typography, TextField, InputAdornment, Tooltip, IconButton, Badge, Paper, Button } from "@mui/material";
import { FilterListIcon, SearchIcon } from "./CoursesIcons";
import CourseInfoCard, { CoursesFlexContainer } from './CourseInfoCard';
import CoursesSettings, { CoursesFilter, CoursesFilterSettings, CoursesGroupBy } from './CoursesSettings';
import { getCourseTypeSubtitle } from './InfoContentMessages';
import { debounce } from 'lodash';

import { fetchCourseSummariesAsync, selectAllUserCourses, selectUserCourseRequirementGroups } from "./coursesSlice";
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { CourseSummary } from "./coursesAPI";
import {  UserCourseRequirementGroup } from "./courseRequirementGroups";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Transcript from "./Transcript";

import Slide from '@mui/material/Slide';

function Alert(props: AlertProps) {
    // TODO: this function appears elsewhere, DRY
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function courseSummariesSort(groupBy: CoursesGroupBy, courseSummaries: CourseSummary[]) {
    let sorted: CourseSummary[] = [];
    sorted = sorted.concat(courseSummaries);

    return sorted.sort((a, b) => {
        switch (groupBy) {
            case "RequirementLevel":
                if (a.userCourse.compulsion < b.userCourse.compulsion) return 1;
                if (a.userCourse.compulsion > b.userCourse.compulsion) return -1;
                break;
            case "Kind":
                if (a.course.details.kind < b.course.details.kind) return -1;
                if (a.course.details.kind > b.course.details.kind) return 1;
                break;
        }
        if (a.course.name < b.course.name) return -1;
        if (a.course.name > b.course.name) return 1;
        return 0;
    });
}

function userCoursesFilter(search: string | null, filter: CoursesFilter, courseSummaries: CourseSummary[]) {

    let filtered = courseSummaries.filter(c => {

        const isNeededIncomplete = c.userCourse.compulsion !== "Optional" && c.userCourse.status !== "Completed";
        if (filter === "NeededIncomplete" && !isNeededIncomplete) return false;

        if (search && search.length > 0) {
            const nameMatches = c.course.name.toLowerCase().indexOf(search.toLowerCase()) >= 0;
            const descMatches = (c.course.description && c.course.description.toLowerCase().indexOf(search.toLowerCase()) >= 0) || false;
            return nameMatches || descMatches;
        }
        return true;
    });
    return filtered;
}

function CourseInfoCards(searchFilter: SearchFilterSettings, courseSummaries: CourseSummary[], userCRGs: UserCourseRequirementGroup[]) {
    let sfCourses = courseSummariesSort(searchFilter.settings.groupBy, userCoursesFilter(searchFilter.search, searchFilter.settings.filter, courseSummaries));
    sfCourses = sfCourses.filter(c => c.course.active && c.course.details.kind !== "InPersonAdHoc");
    // TODO: sort userCRGs after adding a DisplayOrder field
    let coursesAddedByCRGs: string[] = []; // array of course uuid's that were added by a CourseRequirementGroup
    let lastGroup = "";
    const cics: JSX.Element[] = [];

    //NOTE: do all courseUid comparisons in same forced case (toLowerCase) - need to find a better solution to mixed case uuid's

    if (searchFilter.settings.groupBy === "RequirementLevel") {
        userCRGs.forEach(crg => {
            let crgCards: JSX.Element[] = [];

            crg.courseUids.forEach(courseId => {
                let cs = sfCourses.find(c => c.course.uid.toLowerCase() === courseId.toLowerCase());
                if (cs) {
                    coursesAddedByCRGs.push(cs.course.uid.toLowerCase());
                    crgCards.push(<CourseInfoCard key={cs.course.uid} inCRG={true} course={cs.course} userCourse={cs.userCourse} history={cs.history} />);
                }
            });

            if (crgCards.length > 0) {
                // courses were added to CRG, add CRG to page
                let msgStatus = crg.satisfiedWhen === "n/a"
                    ? ""
                    : crg.isSatisfied
                    ? `This requirement is satisfied${crg.satisfiedUntil ? " until " + crg.satisfiedUntil + "." : "."}`
                    : "You have not satisfied this requirement.";

                const crgPaper =
                    <Paper key={crg.uid} style={{ backgroundColor: "#F4F6F9", width: "100%", marginBottom: "1rem" }}>
                        <div style={{ margin: "0.75rem" }}>
                            <Typography variant="h5" component="h2">
                                {crg.name}
                            </Typography>
                            <Typography variant="subtitle2" gutterBottom>{msgStatus}</Typography>
                            <Typography variant="body2" color="textSecondary" component="p" gutterBottom>{crg.description}</Typography>
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                            {crgCards}
                        </div>
                    </Paper>;
                cics.push(crgPaper);
            }
        });
    }

    // cannot use map as one iteration may need two+ elements added to the returned array. 
    sfCourses.forEach(uc => {
        if (!coursesAddedByCRGs.includes(uc.course.uid.toLowerCase())) {
            let currentGroup = "";
            let backgroundColor = "inherit";
            switch (searchFilter.settings.groupBy) {
                case "RequirementLevel":
                    currentGroup = `${uc.userCourse.compulsion} Courses`;
                    switch (uc.userCourse.compulsion) {
                        case "Required":
                            backgroundColor = "#264a6d";
                            break;
                        case "Recommended":
                            backgroundColor = "#4a6783";
                            break;
                        case "Optional":
                            backgroundColor = "#8099ad";
                            break;
                    }
                    break;
                case "Kind":
                    currentGroup = `${getCourseTypeSubtitle(uc.course)}s`;
                    backgroundColor = "#264a6d";
                    break;
                case "None":
                    currentGroup = "";
                    break;
            }
            if (lastGroup !== currentGroup) {
                lastGroup = currentGroup;

                cics.push(<Typography key={currentGroup} variant="h6" component="h6" gutterBottom style={{ width: "100%", color: backgroundColor === "inherit" ? "black" : "white", fontWeight: "bolder", paddingLeft: backgroundColor === "inherit" ? "0" : "1rem", marginBottom: "0.5rem", marginTop: "0.5rem", backgroundColor: backgroundColor }}>
                    {`${currentGroup}`}
                </Typography>);
                cics.push(<CourseInfoCard key={uc.course.uid} inCRG={false} course={uc.course} userCourse={uc.userCourse} history={uc.history} />);
            } else {
                cics.push(<CourseInfoCard key={uc.course.uid} inCRG={false} course={uc.course} userCourse={uc.userCourse} history={uc.history} />);
            }
        }
    });
    return cics;
}

type SearchFilterSettings = { search: string | null, dialogOpen: boolean, settings: CoursesFilterSettings; };
export default function Courses() {
    const courseSummaries = [...useAppSelector(selectAllUserCourses)];
    const userCourseRequirementGroups = useAppSelector(selectUserCourseRequirementGroups);
    const dispatch = useAppDispatch();
    const userCoursesStatus = useAppSelector(state => state.userCourses.status);
    const userProfile = useAppSelector(state => state.auth.user?.profile);
    const authUser = useAppSelector(state => state.auth.user);
    const isValidUserProfile = Boolean(userProfile && userProfile.isValid);
    const error = useAppSelector(state => state.userCourses.error);
    const [viewTranscript, setViewTranscript] = React.useState(false);

    useEffect(() => {
        // reload course status whenever user profile changes (which includes the first time the component is mounted)
        if (userCoursesStatus !== "loading") {
            dispatch(fetchCourseSummariesAsync());
        }


        // if (userCoursesStatus === "idle") {
        //     dispatch(fetchCourseSummariesAsync());
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile]);

    const [searchFilterSettings, setSearchFilterSettings] = React.useState<SearchFilterSettings>({ search: null, dialogOpen: false, settings: { groupBy: "RequirementLevel", filter: "All" } });


    const handleCoursesSettingsClose = (filterSettings: CoursesFilterSettings) => {
        setSearchFilterSettings({
            search: searchFilterSettings.search,
            dialogOpen: false,
            settings: filterSettings
        });
    };
    const handleCourseSettingsOpen = () => {
        setSearchFilterSettings({
            search: searchFilterSettings.search,
            dialogOpen: true,
            settings: searchFilterSettings.settings
        });
    };

    const debouncedSearch = debounce(function (search: string) {
        setSearchFilterSettings({
            search: search,
            dialogOpen: searchFilterSettings.dialogOpen,
            settings: searchFilterSettings.settings
        });
    }, 1000);
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        debouncedSearch((event.target as HTMLInputElement).value);
    };
    const settingsIsDefault = searchFilterSettings.settings.filter === "All" && searchFilterSettings.settings.groupBy === "RequirementLevel";

    let coursesContent: React.ReactNode;
    switch (userCoursesStatus) {
        case "loading":
            coursesContent = <div>Loading...</div>;
            break;
        case "succeeded":
            coursesContent = CourseInfoCards(searchFilterSettings, courseSummaries, userCourseRequirementGroups);
            break;
        case "failed":
            coursesContent = <div>{error}</div>;
            break;
    }
    return (
        <div>
            <div>
                <Slide direction="up" in={!isValidUserProfile && userCoursesStatus === "succeeded"} mountOnEnter unmountOnExit>
                    <Paper elevation={4} style={{marginBottom: "1rem"}}>
                        <Alert severity="warning">Please update your User Profile to view accurate requirements</Alert>
                    </Paper>
                </Slide>
            </div>
            <CoursesSettings open={searchFilterSettings.dialogOpen} onClose={handleCoursesSettingsClose} filterSettings={searchFilterSettings.settings} />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <TextField
                    fullWidth
                    // style={{ width: "calc((100% - 3rem) * 0.9)" }}
                    placeholder="Search"
                    type="search"
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Tooltip title="Sort/Filter Courses">
                    <IconButton onClick={handleCourseSettingsOpen} size="large">
                        <Badge color="error" variant="dot" invisible={settingsIsDefault}>
                            <FilterListIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>            
            
            {viewTranscript &&
                <div>
                    <div style={{ width: "100%", textAlign: "right" }}>
                        <Button color="primary" onClick={() => setViewTranscript(false)}>Close Transcript</Button>
                    </div>
                    <Transcript user={authUser} records={courseSummaries} />
                </div>
            }
            {!viewTranscript &&
                <>
                    <div style={{ width: "100%", textAlign: "right" }}>
                        <Button color="primary" onClick={() => setViewTranscript(true)}>View Transcript</Button>
                    </div>
                    <div>
                        <CoursesFlexContainer>
                            {coursesContent}
                        </CoursesFlexContainer>
                    </div>
                </>
            }
                        
        </div>
    );
}