import { Box, Button, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { AppConfig, getDomainRecordValidator, userProfileDS } from "app/runtimeConstants";
import JsonViewModal from "features/common/JsonViewModal";
import { DomainRecordAction, DomainRecordValidator } from "selign-domain-model";
import React from "react";
/*
The "fix" just posts the cast UP to JsonData
unfixables are failed casts
fixable errors aren't really fixable...

Fix should be changed to a check to see if the UP is in old format or new

remember, running this in development will result in everything happening twice
*/
function mapUserProfile<T extends DomainRecordAction>(datum: any, action: T) {
    const drv = getDomainRecordValidator(userProfileDS);
    return drv.validate(datum, action)
        .map(v => v)
        .mapErr(v => v);
}

async function getAllCurrentUserProfiles(token: string) {

    const response = await fetch(`${AppConfig.url.api}/data/admin/schemaname/userprofile/current?a=ManageUsers`, {
        headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
        credentials: "include"
    });
    if (response.ok) {
        const data = await response.json();
        const drv = getDomainRecordValidator(userProfileDS);

        const defaults = drv.getDefaultValues("update").value;

        const profiles = [];
        for (let i = 0; i < data.length; i++) {
            const element = data[i];

            // ...defaults
            const upAny = { ...JSON.parse(element.data), uid: element.uid, email: "nobody@home.com" };
            const v = (await mapUserProfile(upAny, "read").resolve()).val;
            const iv = drv.getPropsForFormik("update", v.value).initialValues;
            profiles.push(
                {
                    any: upAny,
                    mapped: v,
                    update: iv,
                    userUid: element["userId"]
                }
            );
        }
        return profiles;
    }
    return [];
}

export default function UpCast() {
    const token = useAppSelector(state => state.oidc.user?.access_token) || "";
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState<{ valid: any[], error: any[], errorCast: any[]; }>({ valid: [], error: [], errorCast: [] });
    const [jvmOpen, setJvmOpen] = React.useState(false);
    const [isFixing, setIsFixing] = React.useState(false);
    const [fixingProgress, setFixingProgress] = React.useState(0); // the count of fixed records
    const [getUrl, setGetUrl] = React.useState("");

    const [jvmGetOpen, setJvmGetOpen] = React.useState(false);
    const [jvmGetData, setJvmGetData] = React.useState({});

    React.useEffect(() => {
        if (loading) {
            getAllCurrentUserProfiles(token).then(v => {
                const d = {
                    valid: v.filter(x => x.mapped.isValid),
                    error: v.filter(x => !x.mapped.isValid && !x.mapped.wasCast),
                    errorCast: v.filter(x => !x.mapped.isValid && x.mapped.wasCast)
                };
                setData(d);
                setLoading(false);
            });
        }
    });

    React.useEffect(() => {
        if (isFixing) {
            const promises: Promise<Response>[] = [];

            data.errorCast.forEach(up => {
                var payload = up.update;
                delete payload.uid;
                delete payload.email;
                delete payload.currentPassword;
                delete payload.confirmNewPassword;
                delete payload.newPassword;

                const datum = {
                    uid: up.any.uid,
                    schemaName: "userProfile",
                    userId: up.userUid,
                    data: JSON.stringify(payload)
                };
                const response = fetch(`${AppConfig.url.api}/data/schemaName/userProfile`, {
                    method: "POST",
                    headers: !token ? {} : { 'Authorization': `Bearer ${token}`, "content-type": "application/json" },
                    credentials: "include",
                    body: JSON.stringify(datum)

                }).finally(() => setFixingProgress(fixingProgress + 1));
                promises.push(response);
            });
            Promise.allSettled([]).finally(() => { // promises
                setIsFixing(false);
                setLoading(true);
            });
        }
    }, [data.errorCast, fixingProgress, isFixing, token]);

    const fixUserProfiles = () => {
        setIsFixing(true);
    };

    const getFetchOnClick = () => {
        const response = fetch(`${AppConfig.url.api}/${getUrl}`, {
            method: "GET",
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            credentials: "include"
        }).then((r) => {
            if (r.ok) {
                r.json().then(j => {
                    setJvmGetData(j);
                    setJvmGetOpen(true);
                });
            }
        });
    };


    return (
        <Box>
            <JsonViewModal
                onClose={() => setJvmOpen(false)}
                open={jvmOpen}
                title="User Profiles"
                json={data}
            />
            <JsonViewModal
                onClose={() => setJvmGetOpen(false)}
                open={jvmGetOpen}
                title="API GET FETCH Response"
                json={jvmGetData}
            />
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User Profile State</TableCell>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Valid</TableCell>
                            <TableCell>{data.valid.length}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Has Errors - cannot cast</TableCell>
                            <TableCell>{data.error.length}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Has Errors - can cast</TableCell>
                            <TableCell>{data.errorCast.length}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ marginTop: "1rem" }}>
                <Button sx={{ display: "inline-block" }} variant="contained" onClick={() => setLoading(true)}>Load</Button>
                <Button sx={{ display: "inline-block", marginLeft: "1rem" }} variant="contained" disabled={data.errorCast.length === 0 && data.error.length === 0 && data.valid.length === 0} onClick={() => setJvmOpen(true)}>View</Button>
                <Button sx={{ display: "inline-block", marginLeft: "1rem" }} variant="contained" disabled={data.errorCast.length === 0 || loading} onClick={() => setIsFixing(true)}>Fix - post casts</Button>
            </Box>
            <Box sx={{ marginTop: "1rem" }}>
                {loading && <LinearProgress />}
                {isFixing && <LinearProgress variant="determinate" value={fixingProgress / data.errorCast.length} />}
            </Box>
            <Box sx={{ marginTop: "1rem" }}>
                <TextField fullWidth sx={{ width: "50%", minWidth: "300px" }} label="API relative Url for GET (no leading slash)" value={getUrl} onChange={(e) => setGetUrl(e.target.value)} />
                <Button variant="contained" onClick={getFetchOnClick}>FETCH</Button>
            </Box>
        </Box>
    );
}