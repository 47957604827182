import { Box, Typography, Tabs, Tab, Tooltip, Paper, Skeleton } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { ElevatedModule } from "selign-domain-model";
import React from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";

import UserGroupsIcon from '@mui/icons-material/Group';
import UsersIcon from '@mui/icons-material/Person';
import ProfileIcon from '@mui/icons-material/AccountTree';
import CoursesIcon from '@mui/icons-material/School';
import MiscIcon from '@mui/icons-material/MiscellaneousServices';
import AdminGrid from "./grid/AdminGrid";
import { AppConfig } from "app/runtimeConstants";

type ModuleName = Lowercase<ElevatedModule["name"]>;
export default function AdminTabs() {
    const routeParams = useParams<{ module: string, uid?: string; }>();
    const location = useLocation();
    const history = useHistory();
    const authUser = useAppSelector(state => state.auth.user);
    if (!(authUser && authUser.areas && authUser.areas.filter(a => a.name === "Admin").length > 0)) throw new Error("Not Authorized");

    const [moduleName, setModuleNameState] = React.useState<ModuleName>(getModuleName(routeParams.module));
    const [editingUid, setEditingUidState] = React.useState(routeParams.uid);
    const ROOT_URL = location.pathname.substring(0, location.pathname.indexOf(moduleName)); // ROOT_URL includes trailing slash ex: /admintabs/courses -> /admintabs/

    const handleModuleChange = (event: React.SyntheticEvent, toModule: ModuleName) => {
        history.push(`${ROOT_URL}${toModule}`);
    };

    return (
        <Box>            
            <Typography variant="h5" gutterBottom>{getModuleFriendlyName(moduleName)} Administration</Typography>
            <Box sx={{ display: "flex", flexGrow: 1, minHeight: "300px" }}>
                <Box sx={{flexGrow: 0, flexShrink: 0}}>
                    <Tabs
                        orientation="vertical"
                        sx={{ borderRight: 1, borderColor: "divider", backgroundColor: "Menu", overflow: "unset", height: "100%" }}
                        onChange={handleModuleChange}
                        value={moduleName}
                    >
                        {getTabs(authUser.areas.filter(a => a.name === "Admin")[0].modules)}
                    </Tabs>
                </Box>
                <Box sx={{ paddingLeft: 3, width: "300px", flexGrow: 1 }}>
                    <AdminGrid
                        domain={AppConfig.dl.getDomain({name: getDomainName(moduleName)})}
                    />
                </Box>
            </Box>
        </Box>
    );


}
function getModuleName(name: string): ModuleName {
    switch (name.toLowerCase()) {
        case "courses": return "courses";
        case "home": return "home";
        case "profile": return "profile";
        case "usergroups": return "usergroups";
        case "users": return "users";
        default: throw new Error(`Invalid Admin Module: ${name}`);
    }
}

function getDomainName(moduleName: ModuleName): string {
    switch (moduleName) {
        case "courses":
            return "courses";
        case "users":
            return "userProfile";
    }
    throw Error("No domain mapping for " + moduleName);
}

function getTabs(modules: ElevatedModule[]) {
    return modules.map(m => {
        const value = m.name.toLowerCase();
        let icon: React.ReactElement = <></>;
        switch (value) {
            case "users":
                icon = <UsersIcon />;
                break;
            case "courses":
                icon = <CoursesIcon />;
                break;
            case "profile":
                icon = <ProfileIcon />;
                break;
            case "home":
                icon = <MiscIcon />;
                break;
            case "usergroups":
                icon = <UserGroupsIcon />;
                break;
            default:
                return null;
        }
        return <Tab icon={icon} value={value} key={value} title={getModuleFriendlyName(getModuleName(m.name))} />;
    });
}

function getModuleFriendlyName(name: ModuleName): string {
    //TODO this shouldnt be necessary
    switch (name) {
        case "courses": return "Courses";
        case "home": return "Misc";
        case "profile": return "User Profile";
        case "usergroups": return "User Groups";
        case "users": return "Users";
        default: return "";
    }
}

function TabPanel(props: { children?: React.ReactNode, index: number, value: number; }) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}
