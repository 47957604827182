import { Box, Tooltip } from "@mui/material";
import { DataElementItem, DataElementTreeItem, DataElementType, Datum_D, Domain, DomainError, DomainRecordBuilder, DomainRecordKey, DomainRecordValues, DomainSchema, Err, IDx_UID, InvalidSchema, Ok, Result, SpecialGridFields } from "selign-domain-model";
import { Column, SelectColumn, SortColumn } from "react-data-grid";
import { CellExpanderFormatter } from "./CellExpanderFormatter";
import GridDetailTabs from "./GridDetailTabs";
import { IconOpenModalDMEditor } from "./IconOpenModalDMEditor";
import ErrorIcon from '@mui/icons-material/Error';
import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import { IconOpenModalDMScheduler } from "./IconOpenModalDMScheduler";

export interface AdminRow {
    status: "Valid" | "Contains Errors";
    errors: string[];
    createdDate: string;
    createdEmail: string;
    schemaType: string;
    type: "MASTER" | "DETAIL";
    expanded: boolean;
    record: Datum_D;

    [fieldName: string]: any;
}
export interface DomainGridConfig {
    /**A function used by DataGrid to get the unique key value for a row */
    rowKeyGetter: (row: DomainRecordValues<DomainSchema>) => any;
    /** Maps the choosable fields (sharedFields) of a domain to the reportLabel from the field's dataElement */
    fieldLabels: Record<DomainRecordKey<DomainSchema>, string>;
    /** contains a column definition for all of the domain's sharedFields, action and status (validation) */
    sharedFieldColumns: Array<Column<AdminRow>>;
    /** contains the array of UserActions to supply to the server for retrieving data */
    adminUserActions: Array<string>;
    /** indicates if the DomainGridConfig object includes an error, convenience property to be used by okOrDefault */
    hasConfigError: boolean;
    /** The default sort set by the Domain */
    defaultSort: SortColumn[];
}

function getFieldLabels<T extends DomainSchema>(ds: T, d: Domain): Record<DomainRecordKey<T>, string> {
    const fieldLabels = d.adminGridConfig.sharedFields.reduce((acc, sfName) => {
        switch (sfName) {
            case "createdDate":
                return { ...acc, [sfName]: "Last Modified Date" };
            case "createdEmail":
                return { ...acc, [sfName]: "Last Modified By" };
            case "schemaType":
                return { ...acc, [sfName]: "Type" };
            default:
                const dfs = ds.fields[sfName];
                const des: DataElementType[] = ds.dataElements.filter(de => de.uid === dfs.dataElementUid);

                if (des.length !== 1) {
                    throw Error(`Expected one matching DataElement for UID '${dfs.dataElementUid}' but found '${des.length}'`);
                }

                return {
                    ...acc,
                    [sfName]: des[0].reportLabel
                };
        }

    }, {});

    // // TODO: check for a reportLabel override in the domain field schema, or possibly prevent overrides for it?
    // const fieldLabels = {
    //     ...Object.entries(ds.fields)
    //         .filter(([propName, dfs]) => d.adminGridConfig.sharedFields.includes(propName))
    //         .reduce((acc, df) => {
    //             const [fieldName, dfs] = df;
    //             const des: DataElementType[] = ds.dataElements.filter(de => de.uid === dfs.dataElementUid);

    //             if (des.length !== 1) {
    //                 throw Error(`Expected one matching DataElement for UID '${dfs.dataElementUid}' but found '${des.length}'`);
    //             }

    //             return {
    //                 ...acc,
    //                 [fieldName]: des[0].reportLabel
    //             };
    //         }, {}),
    //     createdDate: "Created/Modified Date",
    //     createdEmail: "Created/Modified By",
    //     schemaType: "Type"
    // };

    return fieldLabels as Record<DomainRecordKey<T> | SpecialGridFields, string>;
}

function getColumns(d: Domain, sharedColSchema: DomainSchema, fieldLabels: DomainGridConfig["fieldLabels"], onRowEditSuccess: () => void): DomainGridConfig["sharedFieldColumns"] {

    // const sc = {
    //     ...SelectColumn,
    //     renderCell(props) {
    //         if (props.row.type !== "DETAIL") {
    //             const f = SelectColumn.renderCell;
    //             if (f) {
    //                 return f(props);
    //             }
    //         }
    //     }
    // } as Column<any>;

    const cols: DomainGridConfig["sharedFieldColumns"] = [

        // sc,
        {
            key: "edit",
            name: "Action",
            frozen: true,
            width: 90,
            sortable: false,
            renderCell(props) {
                if (props.row.type !== "DETAIL") {                    
                    let actions = [<IconOpenModalDMEditor
                        domain={d}                        
                        action={"update"}
                        initialValues={props.row.record.data}
                        onSuccess={onRowEditSuccess}
                        key="1"
                    />];

                    // TODO: make this switch derive its logic from the domainschema (functionality needs to be added there instead of here)
                    // the uid values below are hardcoded to omd-domain-models
                    switch (props.row.record.data.schema[IDx_UID]) {
                        case "bcb7c841-1b5b-4ac0-83c3-92a217017627":
                            // ScheduledEventDS
                            actions.push(
                                <IconOpenModalDMScheduler
                                    eventName={props.row.record.data.value!["courseName"] ?? ""}
                                    eventDesc={props.row.record.data.value!["description"] ?? ""}
                                    eventUid={props.row.record.uid}
                                    onSuccess={function (): void {
                                        throw new Error("Function not implemented.");
                                    }}
                                    key="2"
                                />
                            );
                    }
                    return <div style={{ display: "flex" }} >{actions}</div>;
                }
            }
        },
        {
            key: "expanded",
            name: "",
            minWidth: 30,
            width: 30,
            colSpan(args) {
                return args.type === "ROW" && args.row.type === "DETAIL" ? 100 : undefined;
            },
            renderCell(props) {

                if (props.row.type === "DETAIL") {                    
                    return (
                        // <GridDetailTabs row={props.row} />
                        <Box sx={{ display: "flex", flexGrow: 1, maxHeight: "300px" }}>
                            <Box sx={{ flexGrow: 1, width: "300px", overflowY: "auto" }}>
                                <GridDetailTabs row={props.row} />
                            </Box>
                        </Box>
                    );
                }
                return (
                    <CellExpanderFormatter
                        expanded={props.row.expanded}
                        tabIndex={props.tabIndex}
                        onCellExpand={() => {
                            props.onRowChange({ ...props.row, expanded: !props.row.expanded });
                        }}
                    />);
            },
        },
        {
            key: "status",
            name: "Status",
            width: 60,
            renderCell(props) {
                if (props.row.type !== "DETAIL") {
                    const hasErrors = Array.isArray(props.row.errors) && props.row.errors.length > 0;
                    const tooltipText = hasErrors ? props.row.errors.map((e: string, idx: number) => <p key={idx}>{e}</p>) : "Valid";

                    return (
                        <Tooltip key={`tt_status_${props.row.uid}`} placement="right" title={tooltipText}>
                            {/* <Box sx={{ cursor: "pointer" }}>{props.row.status}</Box> */}
                            <Box sx={{ cursor: "pointer" }}>{hasErrors ? <ErrorIcon sx={{ verticalAlign: "middle", color: "red", width: "100%" }} /> : <VerifiedIcon sx={{ verticalAlign: "middle", color: "green", width: "100%" }} />}</Box>
                        </Tooltip>
                    );
                }
            }
        },

    ];
    return [
        ...cols,
        ...Object.entries(fieldLabels).map(
            ([fieldName, label]) => {
                return {
                    key: fieldName,
                    name: label,
                    renderCell(props) {
                        const value = props.row[props.column.key];
                        const dfs = sharedColSchema.fields[fieldName];
                        if (!dfs) {
                            //likely a field like createdDate etc
                            return value;
                        }
                        const de = sharedColSchema.dataElements.filter(f => f.uid === dfs.dataElementUid)[0];


                        if (de.formElementOptions) {
                            if (de.formElement === "tree") {
                                const treeItems = de.formElementOptions.items;
                                let label = "";
                                treeItems.forEach(i => {
                                    if (label === "") {
                                        const res = searchTree(i, value);
                                        if (res) {
                                            label = res;
                                        }
                                    }
                                });
                                return label !== "" ? label : value;
                            } else if (de.formElement === "choice" && Array.isArray(de.formElementOptions.items)) {
                                //TODO handle case when items come from a function
                                const items: DataElementItem[] = de.formElementOptions.items;
                                return items.filter(f => f.id === value)[0].label;
                            }
                        }

                        return value;
                    }
                } as Column<any>;
            }
        )
    ];
}

export default function getDomainGridConfig(d: Domain, onRowEditSuccess: () => void): Result<DomainGridConfig, DomainError<"InvalidSchema">> {
    // the only choosable columns are shared across all possible schemas, so getting the field info from schemas[0] should be fine

    const dsResult = DomainRecordBuilder.getSchemas(d); //[0];  // u.isActionDsMap(dm.actionMap) ? dm.actionMap.schema : dm.actionMap.schemas[0].schema;
    if (!dsResult.ok) {
        return new Err(InvalidSchema(`No schemas found for domain`, { domain: d }, dsResult.val));
    }
    const ds = dsResult.val[0];

    const a = DomainRecordBuilder.getActions("admin", d);
    const fieldLabels = getFieldLabels(ds, d);    
    return new Ok({
        rowKeyGetter: <T extends DomainSchema>(row: DomainRecordValues<T>) => row[d.adminGridConfig.key],
        fieldLabels: fieldLabels,
        sharedFieldColumns: getColumns(d, ds, fieldLabels, onRowEditSuccess),
        adminUserActions: a, // u.isActionDsMap(dm.actionMap) ? [dm.actionMap.adminAction] : dm.actionMap.schemas.map(s => s.adminAction)
        hasConfigError: false,
        defaultSort: [{columnKey: d.adminGridConfig.defaultSort[0], direction: d.adminGridConfig.defaultSort[1]}]
    });
}

function searchTree(node: DataElementTreeItem, searchId: number | string): string | undefined {
    if (String(node.id) === String(searchId)) {
        return node.label;
    }
    if (Array.isArray(node.children)) {
        for (var treeNode of node.children) {
            const childResult = searchTree(treeNode, searchId);

            if (typeof childResult === "string") {
                return childResult;
            }
        }
    }
}
