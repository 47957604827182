import type { IdParams, RequireAtLeastOne, DomainRecordValues, DomainSchema, DomainRecordAction, Datum_C, Datum_U, Datum_D, ApiSaveParentFn, ApiNestedFn, Domain, ApiGetParentsFn, DomainRecordValid, DomainRecordError } from "selign-domain-model";
import { AppConfig } from "app/runtimeConstants";
import { splitApi } from "./api";
import { getDomainRecordBuilder } from "app/runtimeConstants";
import type { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import type { BaseQueryApi, QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";


/*
Some definitions relevant to the comments throughout this file:

Nested Entity - an entity referenced by a domain schema (ex: courses schema references courseRequirementGroups, UserGroups etc.)

Types of Nested Entities:
Fetch Simple Type AKA WithParent
    - Can be fetched when the parent entity is fetched (using same rtk endpoint as parent, ie: getJsonData)
        - it is not network intensive to get the data for every record 
        - or needs to be available as a grid column
    - the fetched values are passed to a transform function

Fetch Complex Type AKA OnDetails:
    - Only fetched when the parent entity details are loaded (edit, view, etc.)
        - may be network intensive
        - may not be compatible with using the same rtk endpoint as parent
    - has a default value when parent is fetched that is replaced

Save Simple Type:
    - custom function, ie (uid, origValues, newValues) => api calls to add/remove from collection as appropriate
    - ex: a user may have ability to add a course to one crg, but not to another
        - add/remove only from the crg's the user has permissions to, leaving the others untouched

NOTE: INSTRUCTIONS ABOVE MAY NOT BE ACCURATE- DomainRecordBuilder NOW USED FOR ALL OF IT
*/

interface DomainRecordsQuery {
    getDomainBy: RequireAtLeastOne<IdParams>;
    elevated: boolean;
}


interface DomainRecordDetailsQuery {
    values: DomainRecordValid<DomainSchema, "read"> | DomainRecordError<DomainSchema, "read">;
    getDomainBy: RequireAtLeastOne<IdParams>;
    schemaId: DomainSchema["id"];
    elevated: boolean;
}

interface DomainRecordFilter {

}

// interface PostDomainRecord<T extends DomainSchema> {
//     values: DomainRecordValues<T>;
//     action: Exclude<DomainRecordAction, "read">;
//     schema: T;
//     serverSchemaName: string;
// }
interface PostDomainRecord {
    values: DomainRecordValues<DomainSchema>;
    action: Exclude<DomainRecordAction, "read">;
    schemaId: DomainSchema["id"];
    domain: Domain;
    elevated: boolean;
}

function getSaveNested(baseQuery: (arg: any) => MaybePromise<QueryReturnValue<unknown, unknown, {}>>) : ApiNestedFn {
    return (async (url, method, body?) => {
        const result = await baseQuery({
            url,
            method,
            body
        }); 
        if("data" in result)
            return result.data;
        throw new Error(`get/save failed`);
    });
}

function saveParent(api: BaseQueryApi): ApiSaveParentFn {
    return async (datum: Datum_C | Datum_U) => {
        const postResult = await splitApi.endpoints.postJsonDatum.initiate(datum)(api.dispatch, api.getState, undefined);
        if ("data" in postResult)
            return postResult.data;
        throw new Error(`save failed`);
    };
}

function getParents(api: BaseQueryApi): ApiGetParentsFn {
    return async (serverSchemaName: string, forActions: string[], includeGroups: boolean) => {
        return (await splitApi.endpoints.getJsonData.initiate({
            schemaName: serverSchemaName,
            actions: forActions,
            includeGroups: includeGroups,
        }, { forceRefetch: api.forced })(api.dispatch, api.getState, undefined)).data || [];
    };
}

export const domainApi = splitApi.injectEndpoints({

    endpoints: (build) => ({
        getDomainRecords: build.query<Datum_D[], DomainRecordsQuery>({
            async queryFn(drq, api, extraOptions, baseQuery) {               
                const d = AppConfig.dl.getDomain(drq.getDomainBy);
                const drb = getDomainRecordBuilder(d, {getParents: getParents(api)}, drq.elevated);
                const records = await drb.getDomainRecords();
                if (records.ok) {
                    return { data: records.val };
                } else {
                    return { error: JSON.stringify(records.val) };
                }
            }
        }),
        getDomainRecordDetails: build.query<DomainRecordValid<DomainSchema, "read"> | DomainRecordError<DomainSchema, "read">, DomainRecordDetailsQuery>({
            async queryFn(drq, api, extraOptions, baseQuery) {
                const d = AppConfig.dl.getDomain(drq.getDomainBy);
                const drb = getDomainRecordBuilder(d, { getSaveNested: getSaveNested(baseQuery)}, drq.elevated);
                const record = await drb.getDatumDomainRecordWithDetails(drq.values);
                if (record.ok) {
                    return { data: record.val };
                } else {
                    return { error: JSON.stringify(record.val) };
                }
            }
        }),
        postDomainRecord: build.mutation<boolean, PostDomainRecord>({
            async queryFn(arg, api, extraOptions, baseQuery) {
                const drb = getDomainRecordBuilder(arg.domain, {getSaveNested: getSaveNested(baseQuery), saveParent: saveParent(api)}, true);
                const saveResult = await drb.saveDomainRecord(arg.values, arg.action, arg.schemaId)
                if(saveResult.ok) {
                    return {data: true};
                } else {
                    return {error: JSON.stringify(saveResult.val)};
                }
            }
        })
    })
});

export const { useGetDomainRecordsQuery, usePostDomainRecordMutation, useGetDomainRecordDetailsQuery } = domainApi;


