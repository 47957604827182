import React, { ChangeEvent } from "react";
import { Avatar, Button, FormControl, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Switch, TextField, Typography } from "@mui/material";
import { useField } from "formik";
import { FMuiProps, FMuiUserProps, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";
import { Autocomplete } from '@mui/material';
import throttle from 'lodash/throttle';
import FaceIcon from '@mui/icons-material/Face';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CreateQuickUserDialog from "features/admin/CreateQuickUserDialog";
import { BasicUserInfo, useLazyGetUserSearchResultQuery } from "app/services/adminApi";
import { EventScheduleItem } from "./FMuiEventScheduler";
import { sortByProp } from "app/runtimeConstants";
import { DateTime } from "luxon";
interface AttendeeInfo extends BasicUserInfo {
    /** value is null when roster === false */
    credit: boolean | null;
    /** value is null when roster === false */
    waitlist: boolean | null;
}

function isCreditAssignable(schedule: EventScheduleItem[]) {
    if (schedule.length === 0)
        return false;

    const firstStart = DateTime.fromISO(schedule.reduce((prev, curr) => DateTime.fromISO(prev.start) < DateTime.fromISO(curr.start) ? prev : curr).start);

    return firstStart <= DateTime.now();
}


export default function FMuiUserField(props: FMuiProps<FMuiUserProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const [searchValue, setSearchValue] = React.useState<BasicUserInfo | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<BasicUserInfo[]>([]);
    const loaded = React.useRef(false);

    const [showAddUser, setAddUser] = React.useState(false);
    const [hasSearched, setHasSearched] = React.useState(false);
    const [quickUserOpen, setQuickUserOpen] = React.useState(false);

    const roster = props.roster ? props.roster : false;
    const maxSeatsField = useField(roster ? roster.maxSeatsFieldName : "ms_x")[0];
    const maxSeats = roster ? Number(maxSeatsField.value) >= 0 ? Number(maxSeatsField.value) : 0 : 0;
    const eventScheduleField = useField(roster ? roster.eventScheduleFieldName : "es_x")[0];
    const eventSchedule = roster ? Array.isArray(eventScheduleField.value) ? eventScheduleField.value as EventScheduleItem[] : [] : [];




    //const [users, setUsers] = React.useState<UserSearchResult[]>([]);
    const ensureUsers: AttendeeInfo[] = ((field.value || []) as BasicUserInfo[]).map(value => {
        const newValue: AttendeeInfo = {
            credit: null,
            waitlist: null,
            ...value //override credit & waitlist with non-null value if present
        };
        return newValue;
    });

    const users: AttendeeInfo[] = ensureUsers.sort((a, b) => sortByProp(a, b, "waitlist")).sort((a, b) => sortByProp(a, b, "surname"));
    const setUsers = (u: AttendeeInfo[]) => helpers.setValue(u);
    const enrolledCount = users.filter(u => !u.waitlist).length;
    const waitlistCount = users.length - enrolledCount;
    const addNextToWaitlist = maxSeats <= enrolledCount;

    const creditAssignable = isCreditAssignable(eventSchedule);

    const [getUserSearchResult, result] = useLazyGetUserSearchResultQuery();

    // const memoSearch = React.useMemo(
    //     () =>
    //         throttle((searchStr: string, callback: (results?: BasicUserInfo[]) => void) => {
    //             // TODO: switch to FetchAPI                 
    //             const url = `${API_URL}/search/admin/user/${encodeURIComponent(searchStr)}`;
    //             fetch(url).then(response => {
    //                 if (response.ok) {
    //                     response.json().then(data => {
    //                         //setHasSearched(true);
    //                         callback(data);
    //                     }).catch(err => {
    //                         callback([]);
    //                     });
    //                 } else {
    //                     callback([]);
    //                 }
    //             }).catch(err => callback([]));
    //         }, 200),
    //     []
    // );

    React.useEffect(() => {
        let active = true;
        if (inputValue === "") {
            // setOptions(value ? [value] : []);
            setOptions([]);
            return undefined;
        }
        getUserSearchResult(inputValue).unwrap().then(results => {
            if (active) {
                let newOptions: BasicUserInfo[] = [];
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                if (newOptions.length === 0) {
                    // push flag value - renderOption will show add user button
                    newOptions.push({ uid: "", email: "", givenName: "", surname: "" });
                }
                setOptions(newOptions);
            }
        });
        // memoSearch(inputValue, (results?: BasicUserInfo[]) => {
        //     if (active) {
        //         let newOptions: BasicUserInfo[] = [];
        //         // if (value) {
        //         //     newOptions = [value];
        //         // }
        //         if (results) {
        //             newOptions = [...newOptions, ...results];
        //         }
        //         if (newOptions.length === 0) {
        //             // push flag value - renderOption will show add user button
        //             newOptions.push({uid: "", email: "", givenName: "", surname: ""});
        //         }
        //         setOptions(newOptions);
        //     }
        // });

        return () => {
            active = false;
        };
    }, [getUserSearchResult, inputValue]); // value

    const removeUser = (user: BasicUserInfo) => {
        setUsers(users.filter(u => u.uid !== user.uid));
    };

    const handleSwitchChange = (uid: string, event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const checked = event.target.checked;

        const idx = users.findIndex(v => v.uid === uid);
        let newUsers = [...users];
        let target = newUsers[idx];

        switch (name) {
            case "credit":
                target.credit = checked;
                break;
            case "enrolled":
                target.waitlist = !checked;
                break;
        }

        setUsers(newUsers);
    };

    const rosterMeta = roster === false ? null : <Typography>Total Seats: {maxSeats} Enrolled: {enrolledCount} Waitlist: {waitlistCount}</Typography>;

    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <Autocomplete
                id={field.name}
                options={options}
                noOptionsText={"Start typing to search for a student by name or email address"}
                getOptionLabel={(option) => (typeof option === "string" ? option : option.email)}
                filterOptions={(opts) => opts.filter(o => users.findIndex(u => o.uid === u.uid) === -1)}
                handleHomeEndKeys
                selectOnFocus
                clearOnBlur
                blurOnSelect
                value={searchValue}

                onChange={(event: any, newValue: BasicUserInfo | null) => {
                    // setOptions(newValue ? [newValue, ...options] : options);
                    // setValue(newValue);

                    // option selected
                    if (newValue && newValue.uid !== "" && !users.find((u) => u.uid === newValue.uid)) {
                        // add user to list                        
                        setUsers([...users, { ...newValue, waitlist: addNextToWaitlist, credit: false }]);
                        //TODO: set formik value with helpers
                    }
                    // reset for another search
                    setOptions([]);
                    setSearchValue(null);
                    setInputValue("");
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (<TextField {...params} label={props.label} fullWidth />)}
                renderOption={(props, option) => {
                    if (option.uid === "") {
                        return (
                            <li {...props} style={{ textAlign: "center", width: "100%" }}>
                                <div>No users found. Click the button below to create a new user account.</div>
                                <div><Button variant="contained" color="primary" onClick={() => setQuickUserOpen(true)}>New User</Button></div>
                            </li>
                        );
                    }
                    return (
                        <li {...props} key={option.uid}>{`${option.surname}, ${option.givenName} (${option.email})`}</li>
                    );
                }}
            />
            {/* <TextField
                fullWidth
                label={props.label}
                // name={field.name}                
                // onChange={field.onChange}
                // onBlur={field.onBlur}
                error={inError}
                helperText={errorMsg}
                // {...inputProps}
                {...field}
            /> */}
            {getFMuiFieldHelperText(props.formHelperText)}

            {rosterMeta}

            <List>
                {
                    users.map(u =>
                        <ListItem key={u.uid}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FaceIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${u.surname}, ${u.givenName}`}
                                secondary={u.email}
                            />
                            <ListItemSecondaryAction>

                                {roster && <FormControlLabel
                                    control={
                                        <Switch
                                            checked={u.waitlist === true ? false : true}
                                            name="enrolled"
                                            onChange={(e) => handleSwitchChange(u.uid, e)}
                                        />
                                    }
                                    label="Enrolled"
                                    labelPlacement="end"
                                />}
                                {roster && creditAssignable && <FormControlLabel
                                    control={
                                        <Switch
                                            checked={u.credit === true ? true : false}
                                            name="credit"
                                            onChange={(e) => handleSwitchChange(u.uid, e)}
                                        />
                                    }
                                    label="Credit"
                                    labelPlacement="end"
                                />}


                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => removeUser(u)}
                                    size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)
                }
                {
                    users.length === 0 && <ListItem key={0}>
                        <ListItemAvatar>
                            <Avatar>
                                <FaceIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="No Students have been Added" />
                    </ListItem>
                }
            </List>
            <CreateQuickUserDialog
                open={quickUserOpen}
                onUserCancel={() => setQuickUserOpen(false)}
                onCreatedQuickUser={(newUser) => {
                    //TODC: write an IsUserSearchResult type guard                     
                    setUsers([...users, { ...newUser as BasicUserInfo, waitlist: addNextToWaitlist, credit: false }]);
                    setQuickUserOpen(false);
                }}
            />
        </FormControl>
    );
}