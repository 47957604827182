
interface CellExpanderFormatterProps {
    tabIndex: number;
    expanded: boolean;
    onCellExpand: () => void;
  }
  
  export function CellExpanderFormatter({
    tabIndex,
    expanded,
    onCellExpand
  }: CellExpanderFormatterProps) {
    function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
      if (e.key === ' ' || e.key === 'Enter') {
        e.preventDefault();
        onCellExpand();
      }
    }
  
    return (
      <div style={{float: "inline-end", display: "table", blockSize: "100%" }}>
        <span onClick={onCellExpand} onKeyDown={handleKeyDown} style={{display: "table-cell", verticalAlign: "middle", cursor: "pointer"}}>
          <span tabIndex={tabIndex}>
            {expanded ? '\u25BC' : '\u25B6'}
          </span>
        </span>
      </div>
    );
  }