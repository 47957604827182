import React from 'react';
import { useField } from 'formik';
import { Select, ListSubheader, FormControl, InputLabel, MenuItem, FormHelperText, Input } from "@mui/material";
import { FMuiProps, FMuiSelectProps, getFMuiFieldErrorState, getFMuiFieldHelperText, fieldOptionsCompareForTextSort, getFilteredPropsForInputSpread, FieldOption } from "./internal";
import { useAppSelector } from "app/hooks";

export default function FMuiSelectField(props: FMuiProps<FMuiSelectProps>) {
    const authUser = useAppSelector(state => state.auth.user);
    const [field, meta] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const labelId = `label-${props.name}`;
    const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiSelectProps>>(props);

    if (field.value === undefined || field.value === null) field.value = "";

    let crgOptions: FieldOption[] = [];
    if (props.isCrg) {
        if (authUser && authUser.areas) {
            const aa = authUser.areas.find(a => a.name === "Admin");
            if (aa) {
                const cm = aa.modules.find(m => m.name === "Courses");
                if (cm) {
                    const a = cm.actions.find(a => a.actionKey === "AssignToCRG");
                    if (a) {
                        a.targetJsonDatumUids.forEach(uid => {
                            // TODO: REPLACE THIS CRG LOOKUP
                            if (uid.toUpperCase() === "B21CDB44-3789-47A0-A048-D94184A0BDE4")
                                crgOptions.push({ id: 1, label: "Annual Hazardous Waste Training" });
                            if (uid.toUpperCase() === "7BF2A7CA-2566-4F42-84A9-AD913871EE3D")
                                crgOptions.push({ id: 2, label: "Environmental Compliance Training for Supervisors" });
                            if (uid.toUpperCase() === "5744BDA3-9DB6-4DBD-B2DC-A876B6C46DD4")
                                crgOptions.push({ id: 3, label: "EPOC Training" });
                            if (uid.toUpperCase() === "C9613306-7794-47B5-B6F9-55C0C4E774E2")
                                crgOptions.push({ id: 4, label: "Annual Environmental Awareness Training" });
                            if (uid.toUpperCase() === "F294CA68-5B9D-41A0-A499-9A10053F4A95")
                                crgOptions.push({ id: 5, label: "Shop Training Materials" });
                        });
                    }
                }
            }
        }
        if(crgOptions.length === 0) {
            crgOptions.push({ id: 5, label: "Shop Training Materials" });
            crgOptions.push({ id: 4, label: "Annual Environmental Awareness Training" });
        }
    }    

    const [options, setOptions] = React.useState(props.isCrg ? crgOptions : Array.isArray(props.options) ? props.options : []);
    
    React.useEffect(() => {
        if(options.length === 0 && props.options instanceof Promise) {
            props.options.then(o => setOptions(o))
        }
    }, [options, props.options]);


    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <InputLabel id={labelId}>{props.label}</InputLabel>
            <Select
                label={props.label}
                multiple={props.multiple}
                labelId={labelId}
                error={inError}
                {...inputProps}
                {...field}
            // input={props.multiple ? <Input /> : undefined}
            >
                {
                    [<ListSubheader key={`lsh-${props.name}`}>{props.label}</ListSubheader>]
                        .concat(options.sort(fieldOptionsCompareForTextSort)
                            .map(option =>
                                <MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>))
                }
            </Select>
            {inError && <FormHelperText error>{errorMsg}</FormHelperText>}
            {getFMuiFieldHelperText(props.formHelperText)}
        </FormControl>
    );
}

