import { eventScheduleDS, getDomainRecordValidator } from "app/runtimeConstants";
import { useHistory } from "react-router-dom";
import { DMForm, DMFormProps } from ".";
import { FormikHelpers } from "formik";
import { DomainRecordValues, DomainSchema } from "selign-domain-model";
import { Box, Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { Calendar, luxonLocalizer} from "react-big-calendar";
import { EventScheduleItem } from "./formElements/FMuiEventScheduler";
import React from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

export default function DmeTestPage() {
    const action = "create";
    const schema = eventScheduleDS;
    const drv = getDomainRecordValidator(schema);
    const { initialValues, validationSchema } = drv.getPropsForFormik("create");
    const [isAdding, setIsAdding] = React.useState(false);

    const [eventSchedule, setEventSchedule] = React.useState<EventScheduleItem[]>([]);

    const handleGetValuesFromFormikContext = (values: DomainRecordValues<typeof schema>) => {
        setEventSchedule(values["schedule"]);
    }

    const dmFormProps: DMFormProps<typeof schema, typeof action> = {
        domainSchema: schema,
        action: "create",
        areInitialValuesValid: false,
        onUserCancel: function (): void {
            //throw new Error("Function not implemented.");
            setIsAdding(false);
        },
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: function (values: DomainRecordValues<DomainSchema>, formikHelpers: FormikHelpers<DomainRecordValues<DomainSchema>>) {
                throw new Error("Function not implemented.");
            }
        },
        getValuesFromFormikContext: handleGetValuesFromFormikContext
    };

    const isAddingTrue = <DMForm {...dmFormProps} />;
    const isAddingFalse = <Button variant="contained" onClick={() => setIsAdding(true)}>Schedule New Event</Button>


    return (
        <Box>
            <Box sx={{marginTop: "1rem"}}>
                <Typography variant="h5">EPOC - Instructor Led Training - TEST</Typography>
            </Box>
            <Box>
                <Calendar 
                    localizer={localizer}
                    events={eventSchedule}
                    startAccessor={(event) => DateTime.fromISO(event.start).toJSDate()}
                    endAccessor={(event) => DateTime.fromISO(event.end).toJSDate()}
                    titleAccessor={(event) => `${event.title}: ${event.location}`}
                    allDayAccessor={(event) => false}
                    
                    style={{height: 300}}                    
                />
            </Box>
            <Box>
                <Typography sx={{margin: "1rem"}}>All past events are finalized.</Typography>
            </Box>
        <Box>
            {isAdding? isAddingTrue: isAddingFalse}
        </Box>
        </Box>
    );
}