import { AppConfig, getDomainRecordValidator } from "app/runtimeConstants";
import { FormikHelpers } from "formik";
import { DomainRecordBuilder, DomainRecordValues, DomainSchema } from "selign-domain-model";
import { DMForm, DMFormProps } from ".";
import { Box } from "@mui/material";

export default function UserRolesTestPage() {
    const action = "create";
    const domain = AppConfig.dl.getDomain({name: "userGroups"});
    
    const schemasResult = DomainRecordBuilder.getSchemas(domain);    
    
    const schema = schemasResult.okOrThrow()[0]; //assumes only one schema

    const drv = getDomainRecordValidator(schema);
    const { initialValues, validationSchema } = drv.getPropsForFormik("create");

    const dmFormProps: DMFormProps<typeof schema, typeof action> = {
        domainSchema: schema,
        action: "create",
        areInitialValuesValid: false,
        onUserCancel: function (): void {
            //throw new Error("Function not implemented.");
            
        },
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: function (values: DomainRecordValues<DomainSchema>, formikHelpers: FormikHelpers<DomainRecordValues<DomainSchema>>) {
                throw new Error("Function not implemented.");
            }
        }        
    };

    return (
        <Box>
           <DMForm {...dmFormProps} /> 
        </Box>
    )    
}