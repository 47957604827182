import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { AdminRow } from "./getDomainGridConfig";
import { getSchema, getSchemaFromLibrary } from "app/runtimeConstants";
import { DomainError, DomainSchema, IDx_NAME, IDx_UID, Result } from "selign-domain-model";
import { Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useGetAdminCourseHistoryQuery } from "app/services/courseApi";
import DataGrid, { Column } from "react-data-grid";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function RowRecordDetails(props: { row: AdminRow, schemaResult: Result<DomainSchema, DomainError<"NotFound">>; }) {
  if (!props.schemaResult.ok) {
    return (
      <Box>Unable to determine schema for row</Box>
    );
  }
  const ds = props.schemaResult.val;
  if (ds.schema.kind !== "stepForm") {
    return <Box>Details view for schemas of this kind have not been implemented</Box>;
  }
  const orderedPairs: [label: string, value: string, fieldName: string][] = [];

  ds.schema.steps.forEach(step => {
    step.fields.forEach(fieldName => {
      // TODO this logic is duplicative of DMForms, also, need a class to manage overrides consistently
      const dataElementUid = ds.fields[fieldName].dataElementUid;
      const de = ds.dataElements.filter(f => f.uid === dataElementUid)[0];
      if (!de.nestedEntity) {
        const label = de.reportLabel === "" ? de.formLabel : de.reportLabel;
        let value: string = props.row.record.data.value ? String(props.row.record.data.value[fieldName]) : "Unknown";
        if (de.formElement === "choice") {
          const items = Array.isArray(de.formElementOptions?.items) ? de.formElementOptions?.items : [];
          if (items && items.length >= 1) {
            const match = items.find(item => item.id.toString() === value);
            value = match?.label || value;
          }
        }
        value = value === "undefined" ? "" : value; // should catch undefined/nan/null etc before here
        if (de.formElement !== "doc") { // bad
          orderedPairs.push([label, value, String(fieldName)]);
        }
      }
    });
  });

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            orderedPairs.map(([label, value, fieldName]) => {
              return (
                <TableRow
                  key={fieldName}
                >
                  <TableCell>{label}</TableCell>
                  <TableCell>{value}</TableCell>
                </TableRow>
              );
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function CourseHistoryReport(props: { courseUid: string; }) {
  const { data, error, isLoading } = useGetAdminCourseHistoryQuery(props.courseUid);

  // const columns: Column<any>[] = [
  //   { key: "student", name: "Student" },
  //   { key: "latestDateTaken", name: "Latest Date Taken" },
  //   { key: "completed", name: "Completed?" }
  // ];

  return (
    // <DataGrid
    //   columns={columns}      
    //   rows={data || []}      
    //   defaultColumnOptions={{
    //     resizable: true,
    //     sortable: true
    //   }}
    //   renderers={{
    //     noRowsFallback:
    //       isLoading
    //         ? <Skeleton variant="rectangular" sx={{ textAlign: "center", gridColumn: "1/-1", minHeight: "300px", height: "100%", width: "100%", minWidth: "300px" }} />
    //         : error !== undefined
    //           ? <div style={{ textAlign: "center", gridColumn: "1/-1" }}>{`Error: ${String(error)}`}</div>
    //           : <div style={{ textAlign: "center", gridColumn: "1/-1" }}>No data to show</div>
    //   }}
    //   style={{height: "100%"}}
    // />
    <Box>
      <Typography sx={{ marginBottom: "1rem" }}>Only recent records are displayed. Use the reports for more information.</Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              <TableCell>Latest Date Taken</TableCell>
              <TableCell>Completed?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              isLoading
                ?
                <TableRow><TableCell colSpan={3}><Skeleton /></TableCell></TableRow>
                :
                (data && data.length > 0 ? data : [{ student: "No data available", latestDateTaken: "", completed: "" }]).map((item, idx) => {
                  return (
                    <TableRow key={idx}>
                      <TableCell>{item.student}</TableCell>
                      <TableCell>{item.latestDateTaken}</TableCell>
                      <TableCell>{item.completed}</TableCell>
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default function GridDetailTabs(props: { row: AdminRow; }) {
  const [value, setValue] = React.useState(0);
  const domainSchemaResult = getSchemaFromLibrary({ name: props.row.record.data.schema[IDx_NAME], uid: props.row.record.data.schema[IDx_UID] });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (

    <Box sx={{ maxHeight: "300px" }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="History" {...a11yProps(1)} />
          {/* <Tab label="Groups" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>

        <RowRecordDetails row={props.row} schemaResult={domainSchemaResult} />

      </TabPanel>
      <TabPanel value={value} index={1}>
        <CourseHistoryReport courseUid={props.row.record.uid} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        No Data
      </TabPanel> */}
    </Box>

  );
}
