import React from "react";
import { IconButton, Menu, MenuItem, Tooltip, Checkbox } from "@mui/material";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Column } from "react-data-grid";
import { DomainGridConfig } from "./getDomainGridConfig";

interface ColumnChooserProps {

    allCols: DomainGridConfig["fieldLabels"],
    selectedCols: DomainGridConfig["fieldLabels"],
    onSelectedColsChange: (selectedCols: DomainGridConfig["fieldLabels"]) => void;

}



export default function ColumnChooser(props: ColumnChooserProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        const checked = event.target.checked;        
        const fieldLabel = props.allCols[fieldName];
        let selected =  {...props.selectedCols};
        if (checked) {
            selected[fieldName] = fieldLabel;
        } else {
            delete selected[fieldName];
        }
        props.onSelectedColsChange(selected);
      };

    const menuItems = Object.entries(props.allCols).map(([fieldName, fieldLabel]) => {
        const checked = fieldName in props.selectedCols;
        return <MenuItem key={fieldName}>
            <Checkbox 
                checked={checked} 
                onChange={(event) => handleChange(event, fieldName)} />
                {fieldLabel}
                </MenuItem>
    })

    return (
        <div>
            <Tooltip title="Choose Columns">
                <IconButton aria-label="Column Chooser" onClick={handleClick}>
                    <ViewColumnIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{ dense: true }}
            >
                {menuItems}
            </Menu>
        </div>
    );
}