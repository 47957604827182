import { Stepper, Step, StepButton, StepLabel, StepContent, Typography, Button } from "@mui/material";
import { DomainSchema, DomainFields } from "selign-domain-model";
import { FormikErrors, FormikProps, FormikTouched } from "formik";
import React, { ForwardedRef } from "react";
import { DMFormAction, DMFormValue } from ".";
import { getFMuiFields } from "./getFMuiFields";



interface FMuiStepperProps<T extends DomainSchema, A extends DMFormAction> {
    isInitialValid: boolean;
    onValidityChange: (isValid: boolean) => void;
    formik: FormikProps<DMFormValue<T, A>>;
    forAction: A;
    domainSchema: T; //TODO may need to make the domainSchema.schema property generic so it can be constrained to StepForm // & { schema: StepForm; }
    resetStateRef: ForwardedRef<{ reset: () => void; } | undefined>;
}

type DomainRecordValue = Record<keyof DomainFields, any>;
export function FMuiStepper<T extends DomainSchema, A extends DMFormAction>(props: FMuiStepperProps<T, A>) {
    if (props.domainSchema.schema.kind !== "stepForm") throw new Error(`A non-StepForm schema ('${props.domainSchema.id}') was passed to StepForm`);

    // convienience constants derived from props
    const { fields, dataElements: des } = props.domainSchema;
    const { steps } = props.domainSchema.schema;
    const formik = props.formik;
    const action = props.forAction;

    //state 
    const [initialState] = React.useState(() => {
        // returns default value for fields that might have changed over time and need to be reset when user resets form
        return {
            activeStep: 0,
            completed: steps.reduce((acc: { [step: number]: boolean; }, stepMeta, idx) => { acc[idx] = props.isInitialValid; return acc; }, {}),
            validationRan: steps.reduce((acc: { [step: number]: boolean; }, stepMeta, idx) => { acc[idx] = props.isInitialValid; return acc; }, {}),
        };
    });
    const [activeStep, setActiveStep] = React.useState(initialState.activeStep);
    const [completed, setCompleted_ONLY_CALL_IN_setCompleted_and_reset] = React.useState(initialState.completed);
    const [validationRan, setValidationRan] = React.useState(initialState.validationRan);

    //custom state handlers
    const setCompleted = (newCompleted: { [step: number]: boolean; }) => {
        const newCompletedSteps = Object.values(newCompleted).reduce((previous, current) => current ? previous + 1 : previous, 0);
        const isCurrentlyValid = allStepsCompleted();
        const willBeValid = totalSteps() === newCompletedSteps;

        if (isCurrentlyValid !== willBeValid) {
            // validity state change
            props.onValidityChange(willBeValid);
        }
        setCompleted_ONLY_CALL_IN_setCompleted_and_reset(newCompleted);
    };

    React.useImperativeHandle(props.resetStateRef, () => {
        return {
            reset: () => {
                setCompleted_ONLY_CALL_IN_setCompleted_and_reset(initialState.completed);
                setValidationRan(initialState.validationRan);
                setActiveStep(initialState.activeStep);
            }
        };
    }, [initialState.activeStep, initialState.completed, initialState.validationRan, setCompleted_ONLY_CALL_IN_setCompleted_and_reset]);




    /** Stores the FMuiField elements for each step stepFields[step:number]: JSX.Element[] */
    const stepFMuiFields = React.useMemo(
        () => {
            const sf = [];
            for (let i = 0; i < steps.length; i++) {
                //TODO steps[i].fields needs to have the fields not part of the action schema filtered
                sf[i] = getFMuiFields(steps[i].fields.filter(s => s in formik.initialValues), action, fields, des);
            }
            return sf;
        }, [steps, action, fields, des, formik.initialValues]
    );
    // step management methods
    const totalSteps = () => steps.length;
    const completedSteps = () => Object.values(completed).reduce((previous, current) => current ? previous + 1 : previous, 0);
    const isLastStep = () => activeStep === totalSteps() - 1;
    const allStepsCompleted = () => completedSteps() === totalSteps();
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !completed[i])
                : activeStep + 1; // results in an activeStep index that exceeds the step count when all steps complete    
        setActiveStep(newActiveStep);
    };
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const handleGotoStep = (step: number) => setActiveStep(step);
    const handleComplete = (goto: "next" | number = "next") => {
        if (completed[activeStep] === false) {
            const newCompleted = { ...completed };
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
        }

        if (goto === "next")
            handleNext();
        else
            handleGotoStep(goto);
    };
    // step validation methods
    const hasErrors = (step: number, formikErrors: FormikErrors<DomainRecordValue>) => {
        let hasErrors = false;
        Object.keys(formikErrors).forEach(errorName => {
            if (steps[step].fields.includes(errorName)) {
                hasErrors = true;
            }
        });
        return hasErrors;
    };
    const validateActiveStep = (goto: "next" | number = "next") => {
        //TODO: Does this need to useEffect? - no, its only called as a click handler
        formik.validateForm().then(formikErrors => {

            // validateForm validates the fields without touching them which would trigger error messages (possibly unecessarily)
            let errors = hasErrors(activeStep, formikErrors);
            if (errors) {
                // touch each field so user sees the errors
                let touch: FormikTouched<DomainRecordValue> = {};
                steps[activeStep].fields.forEach((name) => {
                    touch[name] = true;
                });
                formik.setTouched(touch);
            } else {
                handleComplete(goto);
            }

            if (validationRan[activeStep] === false) {
                let newVR = { ...validationRan };
                newVR[activeStep] = true;
                setValidationRan(newVR);
            }
        });
    };

    //// its possible to be on the last step, everything is validated, click next, it disables, then the user changes something
    if (completed[activeStep] && hasErrors(activeStep, formik.errors)) {
        const newCompleted = { ...completed };
        newCompleted[activeStep] = false;
        setCompleted(newCompleted);
    }

    return (
        <Stepper
            nonLinear={true}
            activeStep={activeStep}
            orientation="vertical"
        >
            {steps.map((iStep, idx) => (
                <Step key={iStep.title} completed={completed[idx]}>
                    <StepButton onClick={() => validateActiveStep(idx)}>
                        {/* 
                            only show the the step in error if the fields have been touched? or visited? 
                            touched: hasErrors(idx, formikProps.errors) && hasTouched(idx, formikProps.touched)
                            visited: visited[idx] && hasErrors(idx, formikProps.errors)
                            the first time they arriive, the field will be marked as visited, but formikProps.errors should be empty for the step since the fields havent been validated
                        */}
                        <StepLabel error={validationRan[idx] && hasErrors(idx, formik.errors)}>{iStep.title}</StepLabel>
                    </StepButton>
                    <StepContent>
                        <Typography>{iStep.description}</Typography>

                        {stepFMuiFields[idx]}

                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                            >Back</Button>
                            <div style={{ display: "inline-flex" }}>
                                <div style={{ display: "inline-block" }}>
                                    <Button
                                        variant={isLastStep() && allStepsCompleted() ? "text" : "contained"}
                                        color="primary"
                                        disabled={isLastStep() && allStepsCompleted()}
                                        onClick={() => validateActiveStep("next")}
                                    >Next</Button>
                                    {/* TODO: Its possible the ir may be oother form controls outside of stepper with recent refactor. may need more logic to determine if "click save to continue" should appear */}
                                    {isLastStep() && allStepsCompleted() && <Typography variant="caption">Click save to continue</Typography>}
                                </div>
                            </div>
                        </div>
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    );
}