import React from 'react';
import { useField } from 'formik';
import { TextField, FormControl, Theme, useTheme } from "@mui/material";
import { FMuiProps, FMuiTextFieldProps, formatDate, getFilteredPropsForInputSpread, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";

// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         extraHelperTextWrapper: {
//             marginTop: -theme.spacing(2)
//         }
//     })
// );


// NOTE: A TextField is made up of a FormControl, InputLabel, Filled/Outlined Input, and FormHelperText
// FMuiTextField is deliberately wrapping a FormControl (TextField) in another FormControl
// the TextField helperText is used exclusively for error reporting
export default function FMuiTextField(props: FMuiProps<FMuiTextFieldProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiTextFieldProps>>(props);
    const theme = useTheme();
    //const classes = useStyles();

    const isDate = props.type === "date" || props.type === "datetime-local" ? true : false;
    let value = field.value === undefined || field.value === null ? "" : field.value;

    React.useEffect(() => {
        if (isDate && value === "") {
            helpers.setValue(formatDate(new Date()));
        }
    });

    const blockENotationFn = props.type === "number" && props.blockENotation
        ? (e: React.KeyboardEvent) => ["e", "E"].includes(e.key) && e.preventDefault()
        : undefined;

    const helperTexts = getFMuiFieldHelperText(props.formHelperText);

    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <TextField
                fullWidth
                label={props.label}
                error={inError}
                helperText={errorMsg}
                {...inputProps}
                {...field}
                onKeyDown={blockENotationFn}
            />
            {/* TODO convert divs and spans to boxes */}
            {helperTexts && <div style={{ marginTop: `-${theme.spacing(2)}` }}>{helperTexts}</div>}
        </FormControl>
    );

}

