import { useAppSelector } from "app/hooks";
import { useField } from "formik";
import { getFMuiFieldErrorState, getFilteredPropsForInputSpread, FMuiProps, FMuiSelectProps, FMuiCourseRequirementGroupProps, fieldOptionsCompareForTextSort, getFMuiFieldHelperText } from "./internal";
import { useGetJsonDataQuery } from "app/services/api";
import { AuthUser, Datum, UserGroup } from "selign-domain-model";
import { FormControl, InputLabel, Select, ListSubheader, MenuItem, FormHelperText, Checkbox, ListItemText, Tooltip, Box } from "@mui/material";


interface FieldOptionD {
    id: string;
    label: string;
    disabled: boolean;
}

/**
 * ISSUE: 
 * the current implementation of UserGroups and CourseRequirementGroups causes a course to appear 
 * in a CRG for all matching UserGroups.
 * 
 * Example: 
 * Course A is assigned to:
 *  - CRGs: "Shop Materials" and "Awareness Training"
 *  - User Groups: "All Users", "Shop 1 Users"
 * 
 * User 1 belongs to the "All Users" group
 * 
 * User 2 belongs to the "All Users" group and "Shop 1 Users"
 * 
 * 
 * Do we need to pair the CRG and UserGroup assignment? 
 * 
 * The commented code below was a start on implementing a "yes" answer, but now I don't think it is necessary
 */

// interface CrgUserGroupOption {
//     /** crgUid, userGroupUid */
//     id: `${string},${string}`;
//     label: {primary: string, secondary: string };
//     disabled: boolean;
// }

// function getUserAssignableCrgUserGroup(user: AuthUser | undefined, schemaActionKey: "ManageODR" |  "ManageOSPM" | "ManageIPE"): CrgUserGroupOption[] {
//     const crgUserGroupOptions: CrgUserGroupOption[] = [];
//     const userAreas = user?.areas ?? [];
//     const adminArea = userAreas.find(a => a.name === "Admin");
//     const courseModule = adminArea?.modules.find(m => m.name === "Courses");
//     const assignableUserGroups: UserGroup[] = [];

//     courseModule?.actions
//         .filter(f => f.actionKey === schemaActionKey)
//         .forEach(action => {
//             if (action.targetUserGroup !== null) {
//                 if(assignableUserGroups.filter(f => f.uid === action.targetUserGroup?.uid).length === 0) {
//                     // the targetUserGroup has not been added to assignableUserGroups
//                     assignableUserGroups.push(action.targetUserGroup);
//                 }
//             }
//         });

//     const assignableCrgUids = getUserAssignableCrgs(user);






//     return crgUserGroupOptions;    
// }


/** Returns a lowercase uid list of user assignable CRG's based on authUser's permissions */
export function getUserAssignableCrgs(user: AuthUser | undefined) {
    const crgUids: string[] = [];
    if (user && user.areas) {
        const aa = user.areas.find(a => a.name === "Admin");
        if (aa) {
            const cm = aa.modules.find(m => m.name === "Courses");
            if (cm) {
                const a = cm.actions.find(a => a.actionKey === "AssignToCRG");
                if (a) {
                    a.targetJsonDatumUids.forEach(uid => {
                        crgUids.push(uid.toLowerCase());
                    });
                }
            }
        }
    }
    // return only unique values
    return crgUids.filter((v, i, a) => a.indexOf(v) === i);
}

/**
 * 
 * @param allCrgs All possible CourseRequirementGroups as Datum[]
 * @param userAssignableCrgUids the Uid's of CourseRequirementGroups that the user can assign/remove the course to/from
 * @param allAssignedCrgs the Uid's of CourseRequirementGroups that the course belongs to, may include CRG's the user cannot assign to
 */
function getCrgFieldOptionD(allCrgs: Datum[], userAssignableCrgUids: string[], allAssignedCrgs: string[]): FieldOptionD[] {
    const options: FieldOptionD[] = [];

    // add enabled options for all user assignable crgs
    userAssignableCrgUids.forEach(uid => {
        options.push({
            id: uid,
            label: getCrgName(allCrgs, uid),
            disabled: false
        });
    });

    // add disabled options for any crgs the course belongs to but cannot be assigned to by the user
    allAssignedCrgs.forEach(uid => {
        if (options.filter(f => f.id === uid).length === 0) {
            // the course is assigned to a crg that the user cannot assign to
            options.push({
                id: uid,
                label: getCrgName(allCrgs, uid),
                disabled: true
            });
        }
    });

    return options;
}

function getCrgName(allCrgs: Datum[], uid: string) {
    const crgDatum = allCrgs.filter(f => f.uid === uid);
    const label = crgDatum.length >= 1 ? crgDatum[0].data["name"] || "Unknown" : "Unknown";
    return label;
}


export function FMuiCourseRequirementGroup(props: FMuiProps<FMuiCourseRequirementGroupProps>) {
    const authUser = useAppSelector(state => state.auth.user);
    const { data: allCrgs, error: allCrgsError, isLoading: allCrgsLoading } = useGetJsonDataQuery({ schemaName: "courseRequirementGroup", actions: ["viewCourse"], includeGroups: false });

    const [field, meta] = useField(props.name);
    field.value = field.value ?? [];

    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiSelectProps>>(props);

    const userAssignableCrgs = getUserAssignableCrgs(authUser);

    const options: FieldOptionD[] = allCrgsLoading || allCrgs === undefined
        ? [{ id: "", label: "Loading", disabled: true }]
        : getCrgFieldOptionD(allCrgs, userAssignableCrgs, field.value);

    const labelId = `label-${props.name}`;

    const fieldOptionsCompareForDisabledSort = (a: FieldOptionD, b: FieldOptionD) => {
        if (a.disabled < b.disabled) return -1;
        if (a.disabled > b.disabled) return 1;
        return 0;
    };

    const renderValue = (selected: any) => {
        const uids = selected as string[];
        const selectedOptions = options.filter(f => uids.includes(f.id)).sort(fieldOptionsCompareForTextSort).sort(fieldOptionsCompareForDisabledSort);
        return selectedOptions.map(m => m.label).join(", ");

        //return uids.map(uid => getCrgName(allCrgs ?? [], uid)).join(", ");

    };
    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <InputLabel id={labelId}>{props.label}</InputLabel>
            <Select
                label={props.label}
                multiple={true}
                labelId={labelId}
                error={inError}
                {...inputProps}
                {...field}
                renderValue={renderValue}
                fullWidth
            // input={props.multiple ? <Input /> : undefined}
            >
                {
                    [<ListSubheader key={`lsh-${props.name}`}>{props.label}</ListSubheader>]
                        .concat(options
                            .sort(fieldOptionsCompareForTextSort)
                            .sort(fieldOptionsCompareForDisabledSort)
                            .map(option =>
                                <MenuItem key={option.id} value={option.id} disabled={option.disabled} >
                                    <Checkbox checked={(field.value as string[]).indexOf(option.id) > -1} />
                                    <ListItemText primary={option.label} />
                                </MenuItem>))
                }
            </Select>
            {inError && <FormHelperText error>{errorMsg}</FormHelperText>}
            {getFMuiFieldHelperText(props.formHelperText)}
        </FormControl>
    );

}