// Interfaces and other data shared among components but not exported by index

import { FormHelperText, SelectProps as MuiSelectPropsAll, TextFieldProps as MuiTextFieldPropsAll, RadioGroupProps as MuiRadioGroupPropsAll } from "@mui/material";
import { FieldInputProps, FieldMetaProps, FieldProps as FormikFieldProps } from "formik";


export type FieldOption = { id: number | string, label: string; };
export interface FMuiTreeItem {
    id: number | string;
    label: string;
    children?: FMuiTreeItem[];
}
export interface FMuiUserItem {
    uid: string;
    surname: string;
    givenName: string;
    email: string;
}

const OmitProps = [
    // properties that the various field controls must do something with and not spread over the input
    "formHelperText",
    "label",
    "children",
    "error",
    "options",
    "showField",
    "isCrg",
    "blockENotation",
    // properties below are to prevent duplication with Formik's FieldInputProps<Value>
    "name",
    "checked",
    "onBlur",
    "onChange",
    "value",
    "multiple",
    "areaName",
    "moduleName",
    "actionKey"
] as const;

type OmitMuiProps = typeof OmitProps[number];

export type FMuiSelectProps = Omit<MuiSelectPropsAll, OmitMuiProps> & { options: FieldOption[] | Promise<FieldOption[]>; multiple?: boolean; isCrg?: boolean; };
export type FMuiTextFieldProps = Omit<MuiTextFieldPropsAll, OmitMuiProps> & { blockENotation?: boolean; };
export type FMuiRadioGroupProps = Omit<MuiRadioGroupPropsAll, OmitMuiProps> & { options: FieldOption[] | Promise<FieldOption[]>; };
// export type FMuiTreeViewProps = Omit<MuiTreeViewPropsAll, OmitMuiProps> & { options: FMuiTreeItem[] };
export type FMuiTreeViewProps = { options: FMuiTreeItem[] | Promise<FMuiTreeItem[]>; multiselect: boolean; };
export type FMuiUserProps = { roster?: { maxSeatsFieldName: string, eventScheduleFieldName: string}}; // selectedUsers: FMuiUserItem[]
export type FMuiColorProps = {};
export type FMuiDocProps = {};
export type FMuiToggleProps = {};
export type FMuiUserGroupProps = Omit<MuiSelectPropsAll, OmitMuiProps> & { areaName: string, moduleName: string, actionKey: string; };

export type FMuiMarkdownProps = {};
export type FMuiCourseProps = {
    accept: string;
    courseNameKey: string; 
    courseUidKey: string;
};

export type FMuiCourseRequirementGroupProps = {

}

export type FMuiEventSchedulerProps = {
    
}

export type SupportedFMuiFieldProps = FMuiSelectProps | FMuiTextFieldProps | FMuiRadioGroupProps | FMuiTreeViewProps | FMuiUserProps | FMuiColorProps | FMuiDocProps | FMuiToggleProps | FMuiUserGroupProps | FMuiMarkdownProps | FMuiCourseProps | FMuiCourseRequirementGroupProps;

interface FMuiBaseProps {
    label: string; // The label or inputLabel of the control
    name: string; // required field for useHook, but optional in props interfaces this interface will merge with
    formHelperText?: string[]; // line or lines of text that appear below the control to explain its function to the user
    disabled?: boolean;
    indent?: number;

    //TODO include format options, types, etc for fields
}
export interface FMuiDependentProps {
    fieldName: string;
    onlyShowWhen: { fieldName: string, hasValue: any; } | undefined;
    overwriteValueWhen: { newValue: any, when: { fieldName: string, hasValue: any; }; } | undefined;
    dependentChild: JSX.Element;
}

type MuiProps<T extends SupportedFMuiFieldProps> = {
    [P in keyof T]: T[P];
};

export type FMuiProps<T extends SupportedFMuiFieldProps> = MuiProps<T> & FMuiBaseProps; //  & FormikFieldProps

export function getFMuiFieldHelperText(formHelperText?: string[]) {
    return formHelperText
        ? <> {formHelperText.map((fht, i) => <FormHelperText key={i}>{fht}</FormHelperText>)} </>
        : null;
}
export function getFMuiFieldErrorState(field: FieldInputProps<any>, meta: FieldMetaProps<any>): [inError: boolean, errorMsg: string | undefined] {
    const inError = meta.touched && meta.error ? true : false;
    const msg = inError ? meta.error : undefined;
    return [inError, msg];
}
export function copyObjectWithoutKeys<O, T extends keyof O>(o: O, keys: T[]): Omit<O, T> {
    let target = Object.assign({}, o);
    keys.forEach(k => delete target[k]);
    return target;
}

export function getFilteredPropsForInputSpread<T extends SupportedFMuiFieldProps>(props: any): Omit<T, typeof OmitProps[number]> {
    let target = Object.assign({}, props);
    OmitProps.forEach(k => delete target[k]);
    return target;
}

export function fieldOptionsCompareForTextSort(a: FieldOption, b: FieldOption) {
    if (a.label < b.label) {
        return -1;
    }
    if (a.label > b.label) {
        return 1;
    }
    // a must be equal to b
    return 0;
}

/** YYYY-MM-DD */
export function formatDate(date: any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
