import React from 'react';
import { useField } from 'formik';
import { FMuiMarkdownProps, FMuiProps, getFilteredPropsForInputSpread, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";
import MDEditor from '@uiw/react-md-editor';
import { FormControl, FormHelperText, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppSelector } from "app/hooks";
import rehypeSanitize from "rehype-sanitize";
import { getInterpolatedMarkdown } from "features/home/HomePage";

export default function FMuiMarkdown(props: FMuiProps<FMuiMarkdownProps>) {
    const authUser = useAppSelector(state => state.auth.user);
    if (!authUser) throw Error("No user logged in");

    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiMarkdownProps>>(props);

    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <MDEditor
                // {...inputProps}
                // {...field}
                value={field.value}
                onChange={(value) => helpers.setValue(value)}
                onBlur={() => helpers.setTouched(true)}
                height={300}
                preview="edit"
                previewOptions={{
                    rehypePlugins: [[rehypeSanitize]],
                }}
                commandsFilter={(command) => ["image", "codeLive", "codePreview"].includes(command.name || "") ? false : command}
            />
            {inError && <FormHelperText error>{errorMsg}</FormHelperText>}
            {getFMuiFieldHelperText(props.formHelperText)}

            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>Preview</AccordionSummary>
                <AccordionDetails>
                    <MDEditor.Markdown
                        source={getInterpolatedMarkdown(field.value, authUser)}
                        rehypePlugins={[[rehypeSanitize]]}                        
                    />
                </AccordionDetails>
            </Accordion>

        </FormControl>
    );

}