import { AppConfig } from "app/runtimeConstants";
import { userManager } from "../auth";

// TODO: Generic API an error handling for this specific one
export interface ScormSession {
    scormSessionId: number;
    status: string;
    courseId: string;
    userId: string;
    datum: ScormDatum;
}

export interface ScormDatum {
    scormDataId: number;
    scormSessionId: number;
    scormMethod: string;
    dataModel: string;
}

export async function getMyScormSession(courseId: string, reset: boolean, reviewMode: boolean) {


    const token = (await userManager.getUser())?.access_token;

    const response = await fetch(`${AppConfig.url.api}/mydata/scorm/session/${courseId}?reset=${reset ? "true" : "false"}&reviewMode=${reviewMode ? "true" : "false"}`, {
        headers: {
            'Authorization': `Bearer ${token}`,
            'credentials': 'include'
        }
    });

    const data: ScormSession = await response.json();
    return data;
}

/**if altData is provided, window.API_1484_11.cmi will not be used */
export async function saveCMI(scormSessionId: number, scormMethod: string, altData?: any) {
    const token = (await userManager.getUser())?.access_token;

    const data = {
        scormSessionId: scormSessionId,
        scormMethod: scormMethod,
        //@ts-ignore
        dataModel: JSON.stringify(altData ? altData : window.API_1484_11.cmi.toJSON())
    };

    const response = await fetch(`${AppConfig.url.api}/mydata/scorm/datum`, {
        method: "post",
        headers: {
            'Authorization': `Bearer ${token}`,
            'credentials': 'include',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    const ssd: ScormDatum = await response.json();
    return ssd;
}