import React from 'react';
import { Redirect, useHistory } from "react-router-dom";
import { doRegisterAsync } from "../auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateUserProfileAsync } from "../auth/authSlice";
import { DMForm, DMFormProps } from "features/dmForms";
import { useSnackbar } from "notistack";
import { getDomainRecordValidator, userProfileDS } from "app/runtimeConstants";

interface UserProfilePageProps {
    action: "register" | "userProfile" | "admin";
}

export default function UserProfilePage(props: UserProfilePageProps) {    
    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useAppDispatch();
    const authUser = useAppSelector(state => state.auth);
    const authError = authUser.error;

    const history = useHistory();
    const dmAction = props.action === "register" ? "create" : "update";
    const schema = userProfileDS;
    const drv = getDomainRecordValidator(schema);
    const { initialValues, validationSchema } = dmAction === "create" ? drv.getPropsForFormik("create") : drv.getPropsForFormik("update", authUser.user?.profile?.value);

    const dmFormProps: DMFormProps<typeof schema, typeof dmAction> = {
        domainSchema: schema,
        action: dmAction,
        areInitialValuesValid: authUser.user?.profile?.isValid || false,  //TODO: this is checking against READ not UPDATE, fix.
        onUserCancel: function (): void {
            history.push("/");
        },
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: (values, formikHelpers) => {
                const { setSubmitting } = formikHelpers;

                //TODO make this DRY
                setSubmitting(true);
                if (dmAction === "create") {
                    drv.validate(values, "create")
                        .map(valid => {
                            return dispatch(doRegisterAsync({ userProfile: valid }))
                                .then(value => {
                                    setSubmitting(false);
                                    // setSubmissionSuccess(true) // update only
                                }).catch(err => {
                                    setSubmitting(false);                                    
                                    console.log("Error during registration submission.", err);
                                    enqueueSnackbar(<div>An error was encountered. Your account may have still been created. Please click <a href="/signin">here</a> to sign in.</div>, {variant: "error", persist: true });
                                });
                        })
                        .mapErr(notValid => {
                            //shouldnt happen since formik already validated
                            setSubmitting(false);                            
                            console.log("Error during profile validation prior to submission", notValid);
                            enqueueSnackbar(<div>An error was encountered. Your account may have still been created. Please click <a href="/signin">here</a> to sign in.</div>, {variant: "error", persist: true });
                        })
                        .resolve();
                }
                if (dmAction === "update") {
                    drv.validate(values, "update")
                        .map(valid => {
                            return dispatch(updateUserProfileAsync({ userProfile: valid }))
                                .then(value => {
                                    setSubmitting(false);
                                    enqueueSnackbar("User Profile updated successfully!", {variant: "success"});
                                }).catch(err => {
                                    setSubmitting(false);
                                    console.log("Error during registration update submission.", err);
                                    enqueueSnackbar("An error was encountered while updating your User Profile. Please try again later.", {variant: "error"});
                                });
                        })
                        .mapErr(notValid => {
                            //shouldnt happen since formik already validated
                            setSubmitting(false);
                            console.log("Error during user profile update prior to submission.", notValid);
                                    enqueueSnackbar("An error was encountered while updating your User Profile. Please try again later.", {variant: "error"});
                        })
                        .resolve();
                }
            }
        }
    };


    if (authError) {        
        console.log("AuthError recorded.", authError);
        enqueueSnackbar(<>{authError?.split("\n").map((e, i) => <div key={i}>{e.trim()}</div>)}</>, {variant: "error", persist: true});
    }    

    return (
        <div>
            <DMForm {...dmFormProps} />

            {!authError && props.action === "register" && authUser.isAuthenticated && <Redirect to="/" />}
        </div>
    );
}
