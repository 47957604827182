import { domainLibrary } from "omd-domain-model";
import { Domain, DomainError, DomainRecordBuilderArgs, DomainRecordValidator, DomainSchema, Err, IDx_NAME, IDx_UID, IDx_VERSION, IdParams, NotFound, Ok, RequireAtLeastOne, Result } from "selign-domain-model";
import { DomainRecordBuilder } from "selign-domain-model";
import { store } from "./store";
const API_URL = process.env.REACT_APP_API_URL === "PATTERN" ? `${window.location.protocol}//api.${window.location.hostname}` : process.env.REACT_APP_API_URL;
const AUTH_URL = process.env.REACT_APP_AUTH_URL === "PATTERN" ? `${window.location.protocol}//auth.${window.location.hostname}` : process.env.REACT_APP_AUTH_URL;
const BYTE_LIMIT = Number(process.env.REACT_APP_BYTE_LIMIT);
const COURSE_BYTE_LIMIT = Number(process.env.REACT_APP_COURSE_BYTE_LIMIT);

if (API_URL === undefined || AUTH_URL === undefined) throw Error(`Invalid runtime configuration.`);

export const AppConfig = {
    url: {
        api: API_URL,
        auth: AUTH_URL
    },
    limits: {
        byte: BYTE_LIMIT,
        courseByte: COURSE_BYTE_LIMIT
    },
    dl: domainLibrary
} as const;

export const getDomainRecordBuilder = (domain: Domain, apiFetchFn: DomainRecordBuilderArgs["apiFetchFn"], elevated: boolean = false) => {
    const authUser = store.getState().auth.user;
    if(!authUser) {
        throw Error(`user not logged in`);
    }
    return new DomainRecordBuilder({
        domain: domain,
        fnLibrary: domainLibrary.fnLib,
        apiFetchFn: apiFetchFn,
        apiBaseUrl: API_URL,
        elevated: elevated,
        authUser: authUser
    });
};
export const getDomainRecordValidator = (schema: DomainSchema) => new DomainRecordValidator(schema, API_URL);

export const quickUserDS = domainLibrary.getUtilitySchema({ name: "quickUser" });
export const userProfileDS = domainLibrary.getUtilitySchema({ name: "userProfile" });
export const eventScheduleDS = domainLibrary.getUtilitySchema({name: "eventSchedule"});

export function getSchemaFromLibrary(searchBy: RequireAtLeastOne<IdParams>): Result<DomainSchema, DomainError<"NotFound">> {
    // TODO: this function could be risky - it may find multiple matches across domains, may also belong in selign-domain-model
    const schemas: DomainSchema[] = [];

    domainLibrary.domains.forEach(domain => {
        if ("viewAction" in domain.actionMap) {
            schemas.push(domain.actionMap.schema);
        } else {
            domain.actionMap.schemas.forEach(actionDsMap => {
                schemas.push(actionDsMap.schema);
            });
        }
    });

    const matches = schemas.filter(f => {
        if (searchBy.name && searchBy.name !== f.id[IDx_NAME]) return false;
        if (searchBy.uid && searchBy.uid !== f.id[IDx_UID]) return false;
        if (searchBy.version && searchBy.version !== f.id[IDx_VERSION]) return false;
        if (!searchBy.version && !searchBy.name && !searchBy.uid) return false;
        return true;
    });

    if (matches.length === 1) {
        return new Ok(matches[0]);
    } else {
        return new Err(NotFound(`A DomainSchema matching '${searchBy}' not found in domainLibrary`, { found: 0, expected: 1, params: { searchBy }, in: { domainLibrary } }));
    }
}

export function getSchema(schemaUid: string, domain: Domain): Result<DomainSchema, DomainError<"NotFound">>;
export function getSchema(schemaId: DomainSchema["id"], domain: Domain): Result<DomainSchema, DomainError<"NotFound">>;
export function getSchema(schemaId: DomainSchema["id"] | string, domain: Domain): Result<DomainSchema, DomainError<"NotFound">> {
    const schemasResult = DomainRecordBuilder.getSchemas(domain);
    if (!schemasResult.ok) {
        return new Err(NotFound(`No schemas found in Domain '${domain.id}'`, { found: 0, expected: ">=1", params: {}, in: domain.id }));
    }
    const schemas = schemasResult.val;

    const matches = typeof schemaId === "string"
        ? schemas.filter(s => s.id[IDx_UID] === schemaId)
        : schemas.filter(s => s.id[IDx_UID] === schemaId[IDx_UID] && s.id[IDx_NAME] === schemaId[IDx_NAME] && s.id[IDx_VERSION] === schemaId[IDx_VERSION]);

    if (matches.length === 1) {
        return new Ok(matches[0]);
    } else {
        return new Err(NotFound(`No matching schema found for record`, { found: matches.length, expected: 1, params: { schemaId }, in: schemas }));
    }
}


// export const getSchemaForRecord = (domain: Domain, record: any): Result<DomainSchema, DomainError<"NotFound">> => {
//      // this function is somewhat duplicative of functionality in DomainRecordBuilder

//      const schemasResult = DomainRecordBuilder.getSchemas(domain);
//      if(!schemasResult.ok) {
//         return new Err(NotFound(`No schemas found in Domain '${domain.id}'`, {found: 0, expected: ">=1", params: {}, in: domain.id }));
//      }
//      const schemas = schemasResult.val;

//      if ("matchDsFn" in domain.actionMap) {
//         const fnUid = domain.actionMap.matchDsFn;
//         const matchFn = domainLibrary.getLibFn(fnUid, "DsMatch");
//         if(matchFn.ok) {
//             // matchFn found
//             const schemaUidResult = matchFn.val(record);
//             if(schemaUidResult.ok) {
//                 //schema uid found
//                 const dsMatch = schemas.filter(s => s.id[IDx_UID] === schemaUidResult.val);
//                 if(dsMatch.length === 1) {
//                     return new Ok(dsMatch[0]);
//                 } else {
//                     return new Err(NotFound(`No matching schema found for record`, {found: dsMatch.length, expected: 1, params: {uid: schemaUidResult.val}, in: schemas}));
//                 }
//             } else {
//                 return new Err(NotFound(`No matching schema found for record`, {found: 0, expected: 1, params: record, in: domain.id}, schemaUidResult.val));
//             }
//         } else {
//             // matchFn not found
//             return new Err(NotFound(`No domain schema matching function found`, {expected: 1, found: 0, params: fnUid, in: "fnLibrary"}, matchFn.val));
//         }
//     } else {
//         //matchFn not needed, should be a single schema in domain
//         if(schemas.length === 1) {
//             return new Ok(schemas[0]);
//         } else {
//             return new Err(NotFound(`An unexpected number of DomainSchemas was found`, {expected: 1, found: schemas.length, params: "", in: schemas}));
//         }
//     }
// }

export function sortByProp<T>(a: T, b: T, prop: keyof T, dir: "ASC" | "DESC" = "ASC") {
    const x = dir === "ASC" ? a : b;
    const y = dir === "ASC" ? b : a;
    if (x[prop] < y[prop]) {
        return -1;
    }
    if (x[prop] > y[prop]) {
        return 1;
    }    
    return 0;
}