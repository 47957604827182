import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export default function GridSearch(props: {onSearch: (value: string) => void}) {
    const [searchStr, setSearchStr] = React.useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchStr(event.target.value);
        props.onSearch(event.target.value);
    };

    return (
        <div style={{ minWidth: "150px" }}>
            <TextField
                // fullWidth
                // style={{ width: "calc((100% - 3rem) * 0.9)" }}
                placeholder="Search"
                type="search"
                value={searchStr}
                onChange={handleChange}
                variant="standard"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
            />
        </div>
    );
}