import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';

import { OidcProvider } from 'redux-oidc';
import userManager from "./features/auth/oidcConfig";
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import App from './App';
// add close action to all snackbars

const notistackRef = React.createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey | undefined) => () => {
  notistackRef?.current?.closeSnackbar(key);
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(  
  <Provider store={store}> 
    {/* @ts-ignore */}   
    <OidcProvider store={store} userManager={userManager}>
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={2}
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        action={(key) => (
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClickDismiss(key)}
            size="large">
            <CloseIcon />
          </IconButton>
        )}
      >
        <App />
      </SnackbarProvider>
    </OidcProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
