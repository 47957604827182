import React from "react";
import MDEditor from '@uiw/react-md-editor';

export default function HomeAdmin() {
  const [value, setValue] = React.useState<string | undefined>("## Welcome {email}\nThis application provides you with access to various training courses and reference materials.\n\nThe following pages are available by clicking the links above:\n\n- [Home](/) - this page\n- [User Profile](/userprofile) - update your user information\n- [Courses](/courses) - view your training requirements and access courses\n- [Downloads](/downloads) - view various training documents\n\nAdministrators:\n\nEnvrionmental - Jennifer Losson - jennifer.m.losson.nfg@army.mil\n\nFor assistance, please contact help@ngor-lms.com\n");
  // TODO: set height dynamically, change preview function on mobile, add more commands for # and links etc  
  return (      
    <div>
      <MDEditor
        value={value}
        onChange={setValue}
        height={500}
      />
      <MDEditor.Markdown source={value} />
    </div>
  );
}