import { Skeleton, Box, SpeedDial, SpeedDialAction, Button } from "@mui/material";
import { useGetDomainRecordsQuery } from "app/services/domainApi";
import JsonViewModal from "features/common/JsonViewModal";
import { DataElementType, Datum, Datum_D, Domain, DomainError, DomainRecord, DomainRecordBuilder, DomainRecordValues, DomainSchema, Err, IDx_NAME, NotFound, Ok, Result } from "selign-domain-model";
import React, { useCallback } from "react";
import 'react-data-grid/lib/styles.css';
import DataGrid, { RowsChangeData, SortColumn } from "react-data-grid";
import getDomainGridConfig, { AdminRow, DomainGridConfig } from "./getDomainGridConfig";
import GridTopBar from "./GridTopBar";
import GridBottomBar from "./GridBottomBar";

import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';

interface AdminGridProps {
    domain: Domain;
    editUid?: string;
}



function getRows(data: Datum_D[], domain: Domain): Result<AdminRow[], DomainError<"NotFound">> {
    const schemasResult = DomainRecordBuilder.getSchemas(domain);
    if (!schemasResult.ok) {
        return new Err(NotFound(`No schemas found in domain`, { found: 0, expected: ">=1", params: {}, in: { domain } }, schemasResult.val));
    }

    const rows: AdminRow[] = data.map(row => {
        const ds = schemasResult.val.filter(s => s.id[IDx_NAME] === row.data.schema[IDx_NAME]);
        if (ds.length !== 1) {
            return {
                status: "Contains Errors",
                errors: ["Schema not found"],
                createdDate: new Date(row.created).toLocaleString(),
                createdEmail: row.createdByEmail,
                schemaType: "Unknown",
                type: "MASTER",
                expanded: false,
                record: row,
                ...row.data.value
            };
        }
        return {
            status: row.data.isValid ? "Valid" : "Contains Errors",
            errors: row.data.kind !== "error" ? [] : Object.entries(row.data.errors).map(([key, value]) => {
                return `${getFieldFriendlyName(ds[0], key)} : ${String(value)}`;
            }),
            createdDate: new Date(row.created).toLocaleString(),
            createdEmail: row.createdByEmail,
            schemaType: ds[0].title,
            type: "MASTER",
            expanded: false,
            record: row,
            ...row.data.value
        };
    });
    return new Ok(rows);
}

function getFieldFriendlyName(ds: DomainSchema, fieldname: string) {
    // this is partly duplicitive of getFieldLabels in getDomainGridConfig
    switch (fieldname) {
        case "createdDate":
            return "Created/Modified Date";
        case "createdEmail":
            return "Created/Modified By";
        case "schemaType":
            return "Type";
        default:
            const dfs = ds.fields[fieldname];
            const des: DataElementType[] = ds.dataElements.filter(de => de.uid === dfs.dataElementUid);

            if (des.length !== 1) {
                return fieldname;
            }

            return des[0].reportLabel;
    }
}

type Comparator = (a: any, b: any) => number;
function getComparator(sortCol: string): Comparator {
    return (a, b) => {
        if (a[sortCol] && typeof a[sortCol] === "number") {
            //return a[sortCol] === b[sortCol] ? 0 : a[sortCol] ? 1 : -1;
            return a[sortCol] - b[sortCol];
        } else {
            return String(a[sortCol]).localeCompare(String(b[sortCol]));
        }
    };
}

function getVisibleColumns(selectedFieldLabels: DomainGridConfig["fieldLabels"], sharedFieldColumns: DomainGridConfig["sharedFieldColumns"], domain: AdminGridProps["domain"]) {
    return sharedFieldColumns.filter(sfc => {
        //  && domain.id[0] === "userProfile"
        if ((sfc.key === "expanded") || sfc.key === "select-row" || sfc.key === "edit" || sfc.key === "status") {
            // handle the always visible columns
            return true;
        }
        return Object.keys(selectedFieldLabels).filter(fieldName => fieldName === sfc.key).length === 1;
    });
    // return sharedFieldColumns.filter(
    //     sfc => (domain.id[0] === "userProfile" && sfc.key === "expanded") || sfc.key === "select-row" || sfc.key === "edit" || sfc.key === "status" || domain.adminGridConfig.defaultFields.includes(sfc.key));
}

function getInitialVisibleFields(fieldLabels: DomainGridConfig["fieldLabels"], defaultFields: AdminGridProps["domain"]["adminGridConfig"]["defaultFields"]) {
    let visibleFields = { ...fieldLabels };
    Object.entries(fieldLabels).forEach(([fieldName, fieldLabel]) => {
        if (!defaultFields.includes(fieldName)) {
            delete visibleFields[fieldName];
        }
    });
    return visibleFields;
}



export default function AdminGrid(props: AdminGridProps) {
    const [isDebugModalClosed, setIsDebugModalClosed] = React.useState(false);

    const { data, error, isLoading, isFetching, refetch } = useGetDomainRecordsQuery({ elevated: true, getDomainBy: { name: props.domain.id[IDx_NAME] } });

    const handleRowRefetch = () => {
        // TODO: firgure out a way to avoid putting the refetch on a delay timer (ex; without, updating a course name may not immediately show up correctly on grid)
        setTimeout(() => refetch(), 200);
        //refetch();
    }

    const { rowKeyGetter, fieldLabels, sharedFieldColumns, adminUserActions, hasConfigError, defaultSort } = getDomainGridConfig(props.domain, handleRowRefetch).okOrDefault({ hasConfigError: true, adminUserActions: [], sharedFieldColumns: [], fieldLabels: {}, rowKeyGetter: (row: DomainRecordValues<DomainSchema>) => 0, defaultSort: [] });


    

    const [selectedFieldLabels, setSelectedFieldLabels] = React.useState(getInitialVisibleFields(fieldLabels, props.domain.adminGridConfig.defaultFields));

    const [cols, setCols] = React.useState(getVisibleColumns(selectedFieldLabels, sharedFieldColumns, props.domain));

    const [sortCols, setSortCols] = React.useState<readonly SortColumn[]>(defaultSort);

    const [searchStr, setSearchStr] = React.useState("");

    const onSearch = (value: string) => {
        setSearchStr(value);
    };

    const onSelectedFieldLabelChange = (newSelectedFieldLabels: typeof fieldLabels) => {
        setSelectedFieldLabels(newSelectedFieldLabels);
        setCols(getVisibleColumns(newSelectedFieldLabels, sharedFieldColumns, props.domain));
    };

    // const [sortColumns, setSortColumns] = React.useState<readonly SortColumn[]>([]);

    // const onSortColumnsChange = React.useCallback((sortColumns: SortColumn[]) => {
    //     setSortColumns(sortColumns.slice(-1));
    // }, []);


    const [rows, setRows] = React.useState(data === undefined ? new Ok([]) : getRows(data, props.domain));
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());

    React.useEffect(() => {                
        if(isFetching) {
            if (isLoading) {
                // first time load
                setRows(getRows([], props.domain));
            } else {
                // has loaded, but refetching
            }            
        } else {
            setRows(getRows((data === undefined ? [] : data), props.domain));       
        }        
    }, [data, isFetching, isLoading, props.domain]);
    //const rows = isLoading || data === undefined ? [] : getRows(data, props.domain);

    // TODO this resorts in a lot of sorting, need to look more closely at dependencies
    const sortedRows = React.useMemo(() => {
        if (rows.ok && rows.val.length > 0 && sortCols.length > 0) {
            return new Ok([...rows.val].sort((a, b) => {                
                for (const sort of sortCols) {
                    const comparator = getComparator(sort.columnKey);
                    const compResult = comparator(a, b);
                    if (compResult !== 0) {
                        return sort.direction === "ASC" ? compResult : -compResult;
                    }
                }
                return 0;
            }));
        }
        return rows;
    }, [rows, sortCols]);


    const filteredRows = React.useMemo(() => {
        if (props.domain.adminGridConfig.searchFields.length > 0 && sortedRows.ok) {
            return new Ok(sortedRows.val.filter((row: any) => {
                let match = false;
                props.domain.adminGridConfig.searchFields.forEach(fieldName => {
                    const value = String(row[fieldName]).toLowerCase();
                    if (value.indexOf(searchStr.toLowerCase()) >= 0) {
                        match = true;
                    }
                });
                return match;
            }));
        }
        return sortedRows;
    }, [props.domain.adminGridConfig.searchFields, searchStr, sortedRows]);

    function onRowsChange(rows: AdminRow[], { indexes }: RowsChangeData<AdminRow>) {
        const row = rows[indexes[0]];
        if (row.type === 'MASTER') {
            if (!row.expanded) {
                rows.splice(indexes[0] + 1, 1);
            } else {
                rows.splice(indexes[0] + 1, 0, { ...row, type: "DETAIL", uid: `${row.uid}_DETAIL`  });
            }
            setRows(new Ok(rows));
        }
    }

    // TODO: Handle !filteredRows.ok


    const rowsForRender = filteredRows.ok ? filteredRows.val : [];
    return (
        <Box>            
            {/* <JsonViewModal onClose={() => { setIsDebugModalClosed(true); }} open={!isLoading && !isDebugModalClosed} title={""} json={data} /> */}
            <GridTopBar
                allCols={fieldLabels}
                onSearch={onSearch}
                selectedCols={selectedFieldLabels}
                onSelectedColsChange={onSelectedFieldLabelChange}
                onForceRefetch={handleRowRefetch}
                />

            <DataGrid
                columns={cols}
                rows={rowsForRender}
                rowKeyGetter={rowKeyGetter}
                sortColumns={sortCols}
                onSortColumnsChange={setSortCols}
                rowHeight={(row) => (row.type !== 'MASTER' ? 300 : 45)}
                onRowsChange={onRowsChange}
                selectedRows={selectedRows}
                onSelectedRowsChange={setSelectedRows}
                defaultColumnOptions={{
                    resizable: true,
                    sortable: true
                }}
                renderers={{
                    noRowsFallback:
                        isLoading
                            ? <Skeleton variant="rectangular" sx={{ textAlign: "center", gridColumn: "1/-1", minHeight: "300px", height: "100%", width: "100%", minWidth: "300px" }} />
                            : error !== undefined
                                ? <div style={{ textAlign: "center", gridColumn: "1/-1" }}>{`Error: ${String(error)}`}</div>
                                : <div style={{ textAlign: "center", gridColumn: "1/-1" }}>No data to show</div>
                }}
                style={{ width: "100%", minHeight: "400px", height: "calc(100vh - 350px)" }}
            />
            <GridBottomBar
                domain={props.domain}
                onNewRecordCreated={handleRowRefetch}
            />

            {/* <SpeedDial
                ariaLabel="Action Menu"
                sx={{ position: "absolute", bottom: 16, left: "50%" }}
                direction="right"
                icon={<MenuOpenIcon />}
            >
                <SpeedDialAction key="Add" icon={<CreateIcon />} tooltipTitle="Add new record" />
                <SpeedDialAction key="Delete" icon={<DeleteIcon />} tooltipTitle="Deactivate selected record(s)" />
            </SpeedDial> */}

            {/* {isLoading && <Skeleton variant="rectangular" height="400px" />}
            {!isLoading && 
                <DataGrid 
                    columns={sharedFieldColumns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    defaultColumnOptions={{
                        resizable: true,                        
                    }}
                    style={{width: "100%", minHeight: "400px", height: "calc(100vh - 250px)"}}
                />} */}


        </Box>
    );
}


