import React from 'react';
import { useField } from 'formik';
import { FormControl, FormLabel, FormControlLabel, FormHelperText, RadioGroup, Radio } from "@mui/material";
import { FMuiProps, FMuiRadioGroupProps, getFMuiFieldErrorState, getFMuiFieldHelperText, getFilteredPropsForInputSpread } from "./internal";

export default function FMuiRadioGroupField(props: FMuiProps<FMuiRadioGroupProps>) {
    const [field, meta] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiRadioGroupProps>>(props);

    const [options, setOptions] = React.useState(Array.isArray(props.options) ? props.options : []);
    
    React.useEffect(() => {
        if(options.length === 0 && props.options instanceof Promise) {
            props.options.then(o => setOptions(o))
        }
    }, [options, props.options]);

    if(field.value === undefined || field.value === null) field.value = "";
    field.value = String(field.value);

    return (
        <FormControl component="fieldset" style={props.indent ? { marginLeft: `${2*props.indent}rem` } : {}}>
            <FormLabel component="legend" error={inError}>{props.label}</FormLabel>
            <RadioGroup {...inputProps} {...field}> 
                {
                    options.map(option =>
                        <FormControlLabel
                            name={field.name}
                            key={option.id}
                            value={option.id.toString()}
                            control={<Radio color="primary" />}
                            label={option.label}
                            disabled={props.disabled ? props.disabled : false }                            
                        />
                    )
                }
            </RadioGroup>
            {inError && <FormHelperText error>{errorMsg}</FormHelperText>}
            { getFMuiFieldHelperText(props.formHelperText) }
        </FormControl>
    );
}