import { AppBar, Box, Dialog, IconButton, Skeleton, Toolbar, Tooltip, Typography, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import React from "react";

import { eventScheduleDS, getDomainRecordValidator } from "app/runtimeConstants";
import { useHistory } from "react-router-dom";

import { FormikHelpers } from "formik";
import { DomainRecordValues, DomainSchema } from "selign-domain-model";

import { DateTime } from "luxon";
import { Calendar, luxonLocalizer } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";
import { DMForm, DMFormProps } from "features/dmForms";
import { EventScheduleItem } from "features/dmForms/formElements/FMuiEventScheduler";

const localizer = luxonLocalizer(DateTime, { firstDayOfWeek: 7 });

interface IconOpenModalDMSchedulerProps {
    eventName: string;
    eventDesc: string;
    eventUid: string;
    onSuccess: () => void;
}

export function IconOpenModalDMScheduler(props: IconOpenModalDMSchedulerProps) {
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);
    const handleOpen = () => setOpen(true);

    const action = "create";
    const schema = eventScheduleDS;
    const drv = getDomainRecordValidator(schema);
    const { initialValues, validationSchema } = drv.getPropsForFormik("create");
    const [isAdding, setIsAdding] = React.useState(false);

    const [eventSchedule, setEventSchedule] = React.useState<EventScheduleItem[]>([]);

    const handleGetValuesFromFormikContext = (values: DomainRecordValues<typeof schema>) => {
        setEventSchedule(values["schedule"]);
    };

    const dmFormProps: DMFormProps<typeof schema, typeof action> = {
        domainSchema: schema,
        action: "create",
        areInitialValuesValid: false,
        onUserCancel: function (): void {
            //throw new Error("Function not implemented.");
            setIsAdding(false);
        },
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: function (values: DomainRecordValues<DomainSchema>, formikHelpers: FormikHelpers<DomainRecordValues<DomainSchema>>) {
                throw new Error("Function not implemented.");
            }
        },
        getValuesFromFormikContext: handleGetValuesFromFormikContext
    };

    const isAddingTrue = <DMForm {...dmFormProps} />;
    const isAddingFalse = <Button variant="contained" onClick={() => setIsAdding(true)}>Schedule New Event</Button>;



    return (
        <Box>
            <Tooltip title="Schedule" placement="right">
                <IconButton size="small" onClick={handleOpen}>
                    <EventIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                onClose={handleClose}
                open={open}
                fullScreen={true}
            >
                <AppBar style={{ position: "relative" }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} size="large">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6">Schedule: {props.eventName}</Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{ margin: "1rem" }}>
                    <Box sx={{ marginTop: "2rem" }}>
                        <Typography variant="h5">{props.eventName}</Typography>
                    </Box>

                    <Box>
                        <Calendar
                            localizer={localizer}
                            events={eventSchedule}
                            startAccessor={(event) => DateTime.fromISO(event.start).toJSDate()}
                            endAccessor={(event) => DateTime.fromISO(event.end).toJSDate()}
                            titleAccessor={(event) => `${event.title}: ${event.location}`}
                            allDayAccessor={(event) => false}

                            style={{ height: 300 }}
                        />
                    </Box>
                    <Box>
                        <Typography sx={{ margin: "1rem" }}>All past events are finalized.</Typography>
                    </Box>

                    <Box>
                        {isAdding ? isAddingTrue : isAddingFalse}
                    </Box>
                </Box>
            </Dialog>
        </Box>
    );


}