import React from 'react';
import { useField } from 'formik';
import { FMuiProps, FMuiToggleProps, getFMuiFieldErrorState } from "./internal";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

export default function FMuiToggleField(props: FMuiProps<FMuiToggleProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);

    //TODO: apply error states and helptext

    return (
        <FormGroup style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <FormControlLabel
                control={<Checkbox checked={field.value} onChange={(e) => helpers.setValue(e.target.checked)} name={field.name} color="primary" />}
                label={props.label}
            />

        </FormGroup>
    );
}